import { createSlice } from '@reduxjs/toolkit';
import { IState, CostCenterRequest, ICostCenter } from './interfaces/CostCenter';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  costCenters: [],
  costCenterAdministrators: [],
};

export const slice = createSlice({
  name: 'costCenter',
  initialState,
  reducers: {
    COST_CENTER_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    COST_CENTER_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    COST_CENTER_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.costCenters = action.payload.result.sort((a:ICostCenter, b:ICostCenter) => {
        if ( a.number.trim() < b.number.trim() ) return -1;
        if ( a.number.trim() > b.number.trim() ) return 1;
        return 0;
      });
    },
    COST_CENTER_CREATED_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.costCenters = [...state.costCenters, action.payload.result];
    },
    COST_CENTER_DELETED_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.costCenters = state.costCenters.filter(
        (item) => item.costCenterID !== action.payload.result.costCenterID
      );
    },
    COST_CENTER_EDITED_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.costCenters = state.costCenters.map((item) => {
        if (item.costCenterID === action.payload.result.costCenterID) {
          return action.payload.result;
        }
        return item;
      });
    },
    COST_CENTER_ADMINISTRATOR_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.costCenterAdministrators = action.payload.result;
    },
    COST_CENTER_ADMINISTRATOR_CREATE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
    },
    COST_CENTER_ADMINISTRATOR_REMOVE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.costCenterAdministrators = state.costCenterAdministrators.filter(
        (item) =>
          item.costCenterAdministratorID !==
          action.payload.result.costCenterAdministratorID
      );
    },
  },
});

export const {
  COST_CENTER_REQUESTED,
  COST_CENTER_FAILED,
  COST_CENTER_SUCCESS,
  COST_CENTER_CREATED_SUCCESS,
  COST_CENTER_DELETED_SUCCESS,
  COST_CENTER_EDITED_SUCCESS,
  COST_CENTER_ADMINISTRATOR_SUCCESS,
  COST_CENTER_ADMINISTRATOR_CREATE_SUCCESS,
  COST_CENTER_ADMINISTRATOR_REMOVE_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetCostCenters = (CompanyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.CostCenter.GetCostCenters(CompanyID),
    },
    onAction: {
      onStart: COST_CENTER_REQUESTED.type,
      onError: COST_CENTER_FAILED.type,
      onSuccess: COST_CENTER_SUCCESS.type,
    },
  });
};

export const CreateCostCenter = (data: CostCenterRequest) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.CostCenter.CreateCostCenter,
      data: data,
    },
    onAction: {
      onStart: COST_CENTER_REQUESTED.type,
      onError: COST_CENTER_FAILED.type,
      onSuccess: COST_CENTER_CREATED_SUCCESS.type,
      showToast: true,
    },
  });
};

export const DeleteCostCenter = (costCenterID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'DELETE',
      url: Endpoints.CostCenter.DeleteCostCenter(costCenterID),
    },
    onAction: {
      onStart: COST_CENTER_REQUESTED.type,
      onError: COST_CENTER_FAILED.type,
      onSuccess: COST_CENTER_DELETED_SUCCESS.type,
      showToast: true,
    },
  });
};

export const EditCostCenter = (
  costCenterID: number,
  data: CostCenterRequest
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'PUT',
      url: Endpoints.CostCenter.EditCostCenter(costCenterID),
      data: data,
    },
    onAction: {
      onStart: COST_CENTER_REQUESTED.type,
      onError: COST_CENTER_FAILED.type,
      onSuccess: COST_CENTER_EDITED_SUCCESS.type,
      showToast: true,
    },
  });
};

export const GetCostCenterAdministrators = (CompanyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.CostCenter.GetCostCenterAdministrator(CompanyID),
    },
    onAction: {
      onStart: COST_CENTER_REQUESTED.type,
      onError: COST_CENTER_FAILED.type,
      onSuccess: COST_CENTER_ADMINISTRATOR_SUCCESS.type,
    },
  });
};

export const CreateCostCenterAdministrator = (data: {
  costCenterID: number;
  userID: string;
}) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.CostCenter.AddCostCenterAdministrator,
      data: data,
    },
    onAction: {
      onStart: COST_CENTER_REQUESTED.type,
      onError: COST_CENTER_FAILED.type,
      onSuccess: COST_CENTER_ADMINISTRATOR_CREATE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const RemoveCostCenterAdministrator = (
  costCenterID: number,
  userID: string
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'DELETE',
      url: Endpoints.CostCenter.RemoveCostCenterAdministrator(
        costCenterID,
        userID
      ),
    },
    onAction: {
      onStart: COST_CENTER_REQUESTED.type,
      onError: COST_CENTER_FAILED.type,
      onSuccess: COST_CENTER_ADMINISTRATOR_REMOVE_SUCCESS.type,
      showToast: true,
    },
  });
};