import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Button,
  IconButton,
  Tooltip,
  Typography,
  Modal,
  CircularProgress,
} from '@mui/material';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import MapIcon from '@mui/icons-material/Map';
import InfoIcon from '@mui/icons-material/Info';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useNavigate } from 'react-router-dom';
import DataDisplay from '../../../components/DataDisplay';
import { useDispatch, useSelector } from 'react-redux';
import {
  RemoveExpense,
  DeleteExpense,
  GetPendingPaginationExpenses,
} from '../../../store/_Entities/Expenses';
import ModalConfirm from '../../../components/ModalConfirm';
import { GetEvaluate } from '../../../store/_Entities/ExpensesReport';
import { GetCompanyConfiguration } from '../../../store/_Entities/CompanyConfiguration';
import { ModalWrapper } from '../../../components/Modal';
import GoogleMapComponent from '../../../components/GoogleMap';
import { numberToCurrency } from '../../../utils/format';

const Expenses = () => {
  const { expenses, expensesReport, user, companyConfiguration } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userHasKmVertical = user.roles.some((role) => role === 'KM');

  const [showDeleteModal, setShowDeleteModal] = useState({
    value: false,
    type: null,
  });

  const [hasSubmmitted, setHasSubmmitted] = useState(false);
  const [showKmWithoutRoute, setShowKmWithoutRoute] = useState(false);
  const [expenseMap, setExpenseMap] = useState({
    show: false,
    origin: null,
    destination: null,
  });

  const [selectedRow, setSelectedRow] = useState(null);

  const [data, setData] = useState([]);

  const [totalRows, setTotalRows] = useState(0);  
  const [pagNum, setPagNum] = useState(0);  
  const [pagSize, setPagSize] = useState(25);

  useEffect(() => {
    dispatch(GetPendingPaginationExpenses(user.authData.sub, pagNum, pagSize));
    dispatch(GetEvaluate());
    dispatch(GetCompanyConfiguration(user.user.companyID));
  }, []);

  useEffect(() => {
    if (expenses?.expensesPagination) {
      setData(expenses?.expensesPagination.data);
      setTotalRows(expenses?.expensesPagination.totalCount);
    }
  }, [expenses?.expensesPagination]);

  useEffect(() => {
    if (hasSubmmitted && !expenses.loading) {
      dispatch(GetPendingPaginationExpenses(user.authData.sub, pagNum, pagSize));
      setHasSubmmitted(false);
    }
  }, [hasSubmmitted, expenses.loading]);

  useEffect(() => {
    const checkKmWithoutRoute =
      companyConfiguration.companyConfigurations?.find(
        (config) => config.companyConfigurationTypeID === 46
      );
    if (checkKmWithoutRoute) {
      setShowKmWithoutRoute(true);
    }
  }, [companyConfiguration.companyConfigurations]);

  const dataDisplayData = {
    valorKm: numberToCurrency(expensesReport?.evaluate?.currentKmValue) || '',
    dataUltimoValorKm: expensesReport?.evaluate?.currentKmDate || '',
    despesas: [
      ...(expensesReport?.evaluate?.registeredCurrency?.map((currencyData) => ({
        label: 'Valor Despesas Cadastradas',
        valor: currencyData
          ? `${currencyData.currency} ${numberToCurrency(currencyData.price)}`
          : '',
      })) || []),
      ...(expensesReport?.evaluate?.kmCurrency?.map((kmData) => ({
        label: 'Valor Despesas KM',
        valor: kmData
          ? `${kmData.currency} ${numberToCurrency(kmData.price)}`
          : '',
      })) || []),

      ...(expensesReport?.evaluate?.kmCurrency?.map((kmData) => ({
        label: `Distância Percorrida`,
        valor: kmData ? ` ${kmData.distance} KM` : '',
      })) || []),
    ],
  };

  const columns = [
    {
      field: 'expenseType',
      headerName: 'Tipo de Despesa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'report',
      headerName: 'Relatório',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Valor',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => numberToCurrency(params.value),
    },
    {
      field: 'kmUnitValue',
      headerName: 'Valor Unitário de KM',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => numberToCurrency(params.value),
    },
    {
      field: 'distance',
      headerName: 'Distância',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'date',
      headerName: 'Data',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString('pt-BR');
      },
    },
    {
      field: 'removerRelatorio',
      headerName: 'Remover Relatório',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          color="secondary"
          onClick={() => {
            setSelectedRow(params.row);
            setShowDeleteModal({ value: true, type: 'removerRelatorio' });
          }}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      ),
    },
    {
      field: 'editarDeletar',
      headerName: 'Editar|Deletar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Box>
          <IconButton
            color="primary"
            onClick={() =>
              navigate(
                params.row.providerID === 3 || params.row.providerID === 2
                  ? `edit/km-manual/${params.row.expenseID}`
                  : params.row.providerID === 4
                  ? `edit/km-interno/${params.row.expenseID}`
                  : `edit/${params.row.expenseID}`
              )
            }
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => {
              setSelectedRow(params.row);
              setShowDeleteModal({ value: true, type: 'deletar' });
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      field: 'mapa',
      headerName: 'Mapa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (params.row.providerID !== 1) {
          return (
            <IconButton
              color="primary"
              onClick={() => {
                setExpenseMap({
                  show: true,
                  origin: {
                    lat: params.row.address[0].lat,
                    lng: params.row.address[0].lng,
                  },
                  destination: {
                    lat:
                      params.row.address[1]?.lat || params.row.address[0].lat,
                    lng:
                      params.row.address[1]?.lng || params.row.address[0].lng,
                  },
                });
              }}
            >
              <MapIcon />
            </IconButton>
          );
        }
        return null;
      },
    },
    {
      field: 'refusedReason',
      headerName: 'Justificativa da Reprovação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        if (params.row.refusedReason) {
          return (
            <Tooltip title={params.row.refusedReason} placement="top">
              <InfoIcon />
            </Tooltip>
          );
        }
        return <div></div>;
      },
    },
  ];

  const handleRemoverRelatorio = () => {
    dispatch(RemoveExpense(selectedRow.expenseID));
    setShowDeleteModal(false);
    setHasSubmmitted(true);
  };

  const handleDeletar = () => {
    dispatch(DeleteExpense(selectedRow.expenseID));
    setShowDeleteModal(false);
    setHasSubmmitted(true);
  };

  const exportHeaders = {
    expenseType: 'Tipo de Despesa',
    costCenter: 'Centro de Custo',
    report: 'Relatório',
    description: 'Descrição',
    price: 'Valor',
    kmUnitValue: 'Valor Unitário de KM',
    distance: 'Distância',
    date: 'Data',
    justification: 'Justificativa da Reprovação',
  };

  const onChangePagNum = (pagNum) => {
    setPagNum(pagNum)
  }
  
  const onChgangePagSize = (pagSize) => {
    setPagSize(pagSize)
  }

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                marginBottom: '10px',
                borderBottom: '1px solid black',
                paddingBottom: 2,
              }}
            >
              <h2>Cadastro de Despesa</h2>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  flexWrap: 'wrap',
                }}
              >
                <Box
                  display={'flex'}
                  justifyContent={'flex-end'}
                  gap={2}
                  sx={{ mt: { xs: 2, md: 0 } }}
                >
                  {userHasKmVertical && (
                    <>
                      {showKmWithoutRoute && (
                        <Button
                          variant="contained"
                          href="/expen/despesas/add/km-interno"
                        >
                          <AddIcon />
                          Novo KM com Deslocamento Interno
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        href="/expen/despesas/add/km-manual"
                      >
                        <AddIcon />
                        Novo KM
                      </Button>
                    </>
                  )}
                  <Button variant="contained" href="/expen/despesas/add">
                    <AddIcon />
                    Novo
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <DataDisplay data={dataDisplayData} />
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.expenseID}
          exportHeaders={exportHeaders}
          loading={expenses.loading}
          pagNum={pagNum}
          pagSize={pagSize}
          totalCount={totalRows}
          onChangePagNum={onChangePagNum}
          onChgangePagSize={onChgangePagSize}
        />
        <ModalConfirm
          show={showDeleteModal.value}
          submit={
            showDeleteModal.type === 'removerRelatorio'
              ? handleRemoverRelatorio
              : handleDeletar
          }
          handleClose={() => setShowDeleteModal({ value: false, type: null })}
          title={
            showDeleteModal.type === 'removerRelatorio'
              ? 'Deseja excluir a despesa do relatório?'
              : 'Deseja excluir a despesa?'
          }
        >
          <Box>
            <Typography variant="subtitle1">
              {showDeleteModal.type === 'removerRelatorio'
                ? 'A despesa será excluida apenas do relatório.'
                : 'A despesa será excluida permanentemente.'}
            </Typography>
          </Box>
        </ModalConfirm>
        <ModalWrapper
          showModal={expenseMap.show}
          title={'Mapa'}
          maxWidth={'lg'}
          closeModalAction={() =>
            setExpenseMap({ show: false, origin: null, destination: null })
          }
        >
          <Box width={'lg'}>
            <GoogleMapComponent
              origin={expenseMap.origin}
              destination={expenseMap.destination}
              estimative={null}
            />
          </Box>
        </ModalWrapper>
      </Container>
    </>
  );
};

export default Expenses;
