import { createSlice } from '@reduxjs/toolkit';
import { IState, ISupervisionGroup } from './interfaces/SupervisionGroup';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  supervisionGroups: [],
};

export const slice = createSlice({
  name: 'supervisionGroup',
  initialState,
  reducers: {
    SUPERVISION_GROUP_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    SUPERVISION_GROUP_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    SUPERVISION_GROUP_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.supervisionGroups = action.payload.result.filter(
        (item: ISupervisionGroup) => item.active === 1
      );
    },
    SUPERVISION_GROUP_CREATED_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.supervisionGroups = [
        ...state.supervisionGroups,
        action.payload.result,
      ];
    },
    SUPERVISION_GROUP_DELETED_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.supervisionGroups = state.supervisionGroups.filter(
        (item: ISupervisionGroup) =>
          item.supervisionGroupID !== action.payload.result.supervisionGroupID
      );
    },
  },
});

export const {
  SUPERVISION_GROUP_REQUESTED,
  SUPERVISION_GROUP_FAILED,
  SUPERVISION_GROUP_SUCCESS,
  SUPERVISION_GROUP_CREATED_SUCCESS,
  SUPERVISION_GROUP_DELETED_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetSupervisionGroups = (costCenterID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.SupervisionGroup.GetSupervisionGroup(costCenterID),
    },
    onAction: {
      onStart: SUPERVISION_GROUP_REQUESTED.type,
      onError: SUPERVISION_GROUP_FAILED.type,
      onSuccess: SUPERVISION_GROUP_SUCCESS.type,
    },
  });
};

export const CreateSupervisionGroup = (data: {
  costCenterID: number;
  description: string;
  active: number;
}) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.SupervisionGroup.CreateSupervisionGroup,
      data,
    },
    onAction: {
      onStart: SUPERVISION_GROUP_REQUESTED.type,
      onError: SUPERVISION_GROUP_FAILED.type,
      onSuccess: SUPERVISION_GROUP_CREATED_SUCCESS.type,
      showToast: true,
    },
  });
};

export const DeleteSupervisionGroup = (supervisionGroupID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'PUT',
      url: Endpoints.SupervisionGroup.DeleteSupervisionGroup(
        supervisionGroupID
      ),
    },
    onAction: {
      onStart: SUPERVISION_GROUP_REQUESTED.type,
      onError: SUPERVISION_GROUP_FAILED.type,
      onSuccess: SUPERVISION_GROUP_DELETED_SUCCESS.type,
      showToast: true,
    },
  });
};
