import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './interfaces/CompanySource';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';
import { ICostCenterAdminCompanies } from './interfaces/CostCenter';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  companySources: [],
};

export const slice = createSlice({
  name: 'companySource',
  initialState,
  reducers: {
    COMPANY_SOURCE_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    COMPANY_SOURCE_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    COMPANY_SOURCE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.companySources = action.payload.result;
    },
  },
});

export const {
  COMPANY_SOURCE_REQUESTED,
  COMPANY_SOURCE_FAILED,
  COMPANY_SOURCE_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetCompanySources = (companyID: string) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.CompanySource.Source(companyID),
    },
    onAction: {
      onStart: COMPANY_SOURCE_REQUESTED.type,
      onError: COMPANY_SOURCE_FAILED.type,
      onSuccess: COMPANY_SOURCE_SUCCESS.type,
    },
  });
};
