import { useState, useMemo } from 'react';
import { Container, Box, Button, Grid } from '@mui/material';
import { ModalWrapper } from '../Modal';
import Footer from '../Footer';
import CustomDataGrid from '../grids/components/CustomDataGrid';
import SearchBar from '../grids/components/SearchBar';
import ExportMenu from '../grids/components/ExportMenu';
import Checked from '../../assets/images/checked.png';
import applyTooltipToColumns from '../../utils/applyTooltipToColumns';

const CustomTable = ({
  columns,
  data,
  getRowId,
  exportHeaders,
  loading,
  hideFooter,
  dataGridInitialState = {},
  pagNum,
  pagSize,
  totalCount,
  onChangePagNum,
  onChgangePagSize
}) => {
  const [search, setSearch] = useState('');
  const [showExportModal, setShowExportModal] = useState({
    title: '',
    show: false,
  });

  const filteredData = useMemo(() => {
    return data.filter((row) => {
      return Object.values(row).some(
        (value) =>
          value && value.toString().toLowerCase().includes(search.toLowerCase())
      );
    });
  }, [data, search]);

  const showExportModalHandler = (title) => {
    setShowExportModal({ title, show: true });
  };

  const gridProps = {
    getRowId,
    disableSelectionOnClick: true,
    loading: loading,
  };

  return (
    <Container
      sx={{ width: '100%', height: '100%', padding: { xs: 2, md: 3 } }}
    >
      <Grid container spacing={2} alignItems="center" mb={2}>
        <Grid item xs={12} md={6}>
          <SearchBar search={search} setSearch={setSearch} />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-end' },
          }}
        >
          <ExportMenu
            data={filteredData}
            showExportModalHandler={showExportModalHandler}
            headers={exportHeaders}
          />
        </Grid>
      </Grid>
      <Box sx={{ minHeight: '500px', width: '100%' }}>
        <CustomDataGrid
          rows={filteredData}
          columns={applyTooltipToColumns(columns, 20)}
          props={gridProps}
          dataGridInitialState={dataGridInitialState}
          pagNum={pagNum}
          pagSize={pagSize}
          totalCount={totalCount}
          onChangePagNum={onChangePagNum}
          onChgangePagSize={onChgangePagSize}
        />
      </Box>
      {!hideFooter && <Footer />}
      <ModalWrapper
        showModal={showExportModal.show}
        closeModalAction={() =>
          setShowExportModal((prev) => ({ ...prev, show: false }))
        }
        title={showExportModal.title}
        img={Checked}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={() =>
              setShowExportModal((prev) => ({ ...prev, show: false }))
            }
          >
            Ok
          </Button>
        </Box>
      </ModalWrapper>
    </Container>
  );
};

export default CustomTable;
