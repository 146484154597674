import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import Header from '../../../components/Header';
import HeaderSelect from '../../../components/HeaderSelect';
import {
  Container,
  IconButton,
  Grid,
  CircularProgress,
  Box,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info'; // Ícone para Despesas
import ApprovalIcon from '@mui/icons-material/Done'; // Ícone para Acompanhar Aprovação
import DownloadIcon from '@mui/icons-material/Download'; // Ícone para Recibo
import HeaderSearchSelect from '../../../components/HeaderSearchSelect';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerRange from '../../../components/DatePickerRange';
import validateDate from '../../../utils/validateDate';
import {
  ConfirmPayment,
  GetFinancialAdmApprovalFlow,
  GetFinancialAdmExpenses,
  GetFinancialAdmReports,
  RefusePayment,
} from '../../../store/_Entities/FinancialAdm';
import { ModalWrapper } from '../../../components/Modal';
import ApprovalStepper from '../../../components/ApprovalStepper';
import axios from 'axios';
import CustomDataGrid from '../../../components/grids/components/CustomDataGrid';

const FinancialManagement = () => {
  const { company, financialAdm, user } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyInputValue, setCompanyInputValue] = useState('');
  const [reportStatus, setReportStatus] = useState(0);
  const [reportNature, setReportNature] = useState(0);
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); //Data 7 dias atrás
  const [endDate, setEndDate] = useState(new Date());

  const [data, setData] = useState([]);

  const [showFlowModal, setShowFlowModal] = useState(false);
  const [showExpenseModal, setShowExpenseModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedExpense, setSelectedExpense] = useState({
    value: null,
    show: false,
  });
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [showJustificationField, setShowJustificationField] = useState(true);
  const [justificationValue, setJustificationValue] = useState(null);

  const columns = [
    {
      field: 'reportID',
      headerName: 'Código',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'created',
      headerName: 'Data de Criação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString('pt-BR');
      },
    },
    {
      field: 'name',
      headerName: 'Nome',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'releaseType',
      headerName: 'Natureza Relatório',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'reportStatus',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'totalAmount',
      headerName: 'Valor Total do Relatório',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
      valueFormatter: (params) => {
        return new Intl.NumberFormat('pt-BR', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(params.value);
      },
    },
    {
      field: 'despesas',
      headerName: 'Despesas',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setSelectedRow(params.row);
            handleDespesas(params.row);
          }}
        >
          <InfoIcon />
        </IconButton>
      ),
    },
    {
      field: 'aprovar',
      headerName: 'Acompanhar Aprovação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            handleAprovar(params.row);
          }}
        >
          <ApprovalIcon />
        </IconButton>
      ),
    },
    {
      field: 'recibo',
      headerName: 'Recibo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const { releaseType } = params.row;

        if (releaseType !== 'Adiantamento') {
          return null;
        }

        return (
          <IconButton onClick={() => handleDownloadPdf(params.row)}>
            <DownloadIcon />
          </IconButton>
        );
      },
    },
  ];

  const expensesColumns = [
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      minWidth: 50,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={() => setSelectedExpense({ value: params.row, show: true })}
        >
          <InfoIcon />
        </IconButton>
      ),
    },
    {
      field: 'expenseType',
      headerName: 'Tipo Despesa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Valor',
      headerAlign: 'center',
      align: 'center',
      minWidth: 80,
      flex: 1,
    },
    {
      field: 'currency',
      headerName: 'Moeda',
      headerAlign: 'center',
      align: 'center',
      minWidth: 70,
      flex: 1,
    },
    {
      field: 'dateStr',
      headerName: 'Data',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'file',
      headerName: 'Arquivo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        return params.row.file !== null ? (
          <IconButton onClick={() => handleExpenseFile(params.row)}>
            <DownloadIcon />
          </IconButton>
        ) : null;
      },
    },
    {
      field: 'warningString',
      headerName: 'Alerta',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
  ];

  const handleDespesas = (row) => {
    const releaseType =
      row.releaseType === 'Adiantamento'
        ? 3
        : row.releaseType === 'Reembolso de Despesas'
        ? 1
        : 2;
    dispatch(
      GetFinancialAdmExpenses(row.reportID, user.authData.sub, releaseType)
    );
    setShowExpenseModal(true);
    setShowJustificationField(false);
    setJustificationValue(null);
  };

  const handleAprovar = (row) => {
    dispatch(GetFinancialAdmApprovalFlow(row.reportID, row.releaseType));
    setShowFlowModal(true);
  };

  const handleDownloadPdf = async (row) => {
    setIsLoadingDownload(true);

    const data = {
      tasks: {
        'task-1': {
          operation: 'capture-website',
          url: `https://wexp-exponential.azurewebsites.net/Invoice/AdvancedInvoiceLayout?id=${row.reportID}`,
          output_format: 'pdf',
          engine: 'chrome',
          zoom: 1,
          page_width: 50,
          page_height: 50,
          print_media_type: true,
          margin_top: 10,
          margin_bottom: 10,
          margin_left: 10,
          margin_right: 10,
          print_background: true,
          display_header_footer: false,
          wait_until: 'load',
          wait_time: 0,
          filename: 'Adiantamento de Despesas.pdf',
        },
        'export-1': {
          operation: 'export/url',
          input: ['task-1'],
          inline: false,
          archive_multiple_files: false,
        },
      },
    };

    try {
      const createJobResponse = await axios.post(
        'https://api.cloudconvert.com/v2/jobs',
        data,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CLOUD_CONVERT_TOKEN} `,
            'Content-Type': 'application/json',
          },
        }
      );

      const id = createJobResponse.data.data.id;

      await axios
        .get(`https://api.cloudconvert.com/v2/jobs/${id}/wait`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CLOUD_CONVERT_TOKEN} `,
          },
        })
        .then((waitResponse) => {
          const fileUrl = waitResponse.data.data.tasks[0].result.files[0].url;
          window.open(fileUrl, '_blank');
        });
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoadingDownload(false);
    }
  };

  const handleExpenseFile = (row) => {
    console.log(row);
  };

  const handleConfirmPayment = () => {
    const data = {
      reportID: selectedRow.reportID,
      releaseType:
        selectedRow.releaseType === 'Adiantamento'
          ? 3
          : selectedRow.releaseType === 'Reembolso de Despesas'
          ? 1
          : 2,
    };
    dispatch(ConfirmPayment(data.reportID, data.releaseType));
    setShowExpenseModal(false);
  };

  const handleRefusePayment = () => {
    if (!showJustificationField) {
      setShowJustificationField(true);
      return;
    }
    if (justificationValue) {
      const data = {
        reportID: selectedRow.reportID,
        releaseType:
          selectedRow.releaseType === 'Adiantamento'
            ? 3
            : selectedRow.releaseType === 'Reembolso de Despesas'
            ? 1
            : 2,
        justification: justificationValue,
      };
      dispatch(
        RefusePayment(data.reportID, data.releaseType, data.justification)
      );
      setShowExpenseModal(false);
    }
  };

  useEffect(() => {
    if (selectedCompany && validateDate(startDate) && validateDate(endDate)) {
      const startDateFormatted = startDate.toLocaleDateString('pt-BR');
      const endDateFormatted = endDate.toLocaleDateString('pt-BR');
      dispatch(
        GetFinancialAdmReports(
          selectedCompany,
          startDateFormatted,
          endDateFormatted,
          reportStatus,
          reportNature
        )
      );
    }
  }, [selectedCompany, startDate, endDate, reportStatus, reportNature]);

  useEffect(() => {
    if (selectedCompany) {
      setData(financialAdm?.reports);
    }
  }, [financialAdm?.reports]);

  const exportHeaders = {
    reportID: 'Código',
    created: 'Data de Criação',
    name: 'Nome',
    releaseType: 'Natureza Relatório',
    description: 'Descrição',
    reportStatus: 'Status',
    value: 'Valor Total do Relatório',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Header title="Administração Financeira - Confirmar Pagamento">
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={12} sm={2.5}>
              <HeaderSearchSelect
                label="Empresas"
                onChange={setSelectedCompany}
                inputValue={companyInputValue}
                inputChange={setCompanyInputValue}
                menuItems={company?.companies?.map((company) => {
                  return { label: company.giveName, value: company.companyID };
                })}
              />
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <HeaderSelect
                label="Status Relatório"
                onChange={(event) => {
                  setReportStatus(event.target.value);
                }}
                menuItems={[
                  { label: 'Todos', value: 0 },
                  { label: 'Pendente', value: 1 },
                  { label: 'Aguardando Aprovação', value: 2 },
                  { label: 'Aprovado', value: 3 },
                  { label: 'Recusado', value: 4 },
                  { label: 'Pagamento Confirmado', value: 5 },
                  { label: 'Finalizado', value: 7 },
                ]}
                defaultValue={undefined}
              />
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <HeaderSelect
                label="Natureza relatório"
                onChange={(event) => {
                  setReportNature(event.target.value);
                }}
                menuItems={[
                  { label: 'Todos', value: 0 },
                  { label: 'Reembolso de Despesas', value: 1 },
                  { label: 'Prestação de Contas', value: 2 },
                  { label: 'Adiantamento', value: 3 },
                ]}
                defaultValue={undefined}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DatePickerRange
                startDate={startDate}
                endDate={endDate}
                onChange={{ startDate: setStartDate, endDate: setEndDate }}
              />
            </Grid>
          </Grid>
        </Header>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.reportID}
          exportHeaders={exportHeaders}
          loading={
            (financialAdm?.loading && !showFlowModal && !showExpenseModal) ||
            isLoadingDownload
          }
        />

        <ModalWrapper
          title={'Visualizar Fluxo de Aprovação'}
          showModal={showFlowModal}
          closeModalAction={() => setShowFlowModal(false)}
          maxWidth={
            financialAdm?.approvalFlow?.approversGroup?.length > 3 ? 'md' : 'sm'
          }
        >
          <Box>
            <Typography variant="h6">Status da aprovação:</Typography>
            <Box display={'flex'} justifyContent={'center'}>
              {financialAdm?.loading ? (
                <CircularProgress />
              ) : (
                <ApprovalStepper
                  data={financialAdm?.approvalFlow?.approversGroup || []}
                />
              )}
            </Box>
          </Box>
        </ModalWrapper>
        <ModalWrapper
          title={
            !financialAdm?.loading &&
            `Confirmar Pagamento: ${financialAdm?.expenses?.report || ''}`
          }
          showModal={showExpenseModal}
          closeModalAction={() => setShowExpenseModal(false)}
          width="50%"
        >
          {financialAdm?.loading ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : (
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              gap={2}
              width={'100%'}
            >
              <Grid container spacing={2} width={{ xs: '100%' }}>
                {selectedRow?.releaseType === 'Prestação de Contas' && (
                  <>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1" fontWeight={'medium'}>
                        Valor do Adiantamento:
                      </Typography>
                      <Typography variant="body1">
                        {financialAdm?.expenses?.reportValue}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1" fontWeight={'medium'}>
                        Valor Total do Relatório:
                      </Typography>
                      <Typography variant="body1">
                        {financialAdm?.expenses?.reportTotalValue}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1" fontWeight={'medium'}>
                        Saldo do Adiantamento:
                      </Typography>
                      <Typography variant="body1">
                        {financialAdm?.expenses?.advancedTotalValue}
                      </Typography>
                    </Grid>
                  </>
                )}
                {selectedRow?.releaseType === 'Reembolso de Despesas' && (
                  <Grid item xs={12}>
                    <Box
                      width={'100%'}
                      display={'flex'}
                      justifyContent={'center'}
                      gap={1}
                    >
                      <Typography variant="body1">
                        {financialAdm?.expenses?.balance}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
              {selectedRow?.releaseType === 'Adiantamento' && (
                <Grid container>
                  <Grid item xs={12}>
                    <Box width={'100%'} display={'flex'} gap={2}>
                      <Typography variant="body1" fontWeight={'medium'}>
                        Descrição do Adiantamento:
                      </Typography>
                      <Typography variant="body1">
                        {financialAdm?.expenses?.report}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box width={'100%'} display={'flex'} gap={2}>
                      <Typography variant="body1" fontWeight={'medium'}>
                        Moeda do Adiantamento:
                      </Typography>
                      <Typography variant="body1">
                        {selectedRow?.releaseType === 'Adiantamento'
                          ? financialAdm?.expenses?.advancedCurrency
                          : financialAdm?.expenses?.currency[0].currency}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box width={'100%'} display={'flex'} gap={2}>
                      <Typography variant="body1" fontWeight={'medium'}>
                        Valor do Adiantamento:
                      </Typography>
                      <Typography variant="body1">
                        {selectedRow?.releaseType === 'Adiantamento'
                          ? financialAdm?.expenses?.advancedValue
                          : financialAdm?.expenses?.advancedValue}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}

              {selectedRow?.releaseType !== 'Adiantamento' && (
                <Box
                  width={{
                    xs: '100%',
                  }}
                  maxWidth="850px"
                >
                  <CustomDataGrid
                    rows={financialAdm?.expenses?.expenses}
                    props={{
                      getRowId: (row) => row.expenseID,
                      loading: financialAdm.loading,
                      disableSelectionOnClick: true,
                    }}
                    columns={expensesColumns}
                    noRowsText={'Nenhuma despesa encontrada'}
                    height={500}
                  />
                </Box>
              )}
              {selectedRow?.reportStatusID === 3 && (
                <Box
                  width={'100%'}
                  display={'flex'}
                  justifyContent={'space-evenly'}
                  flexDirection={{
                    xs: 'column',
                    md: 'row',
                  }}
                  gap={2}
                >
                  {showJustificationField && (
                    <Box>
                      <TextField
                        id="justification"
                        label={'Justificativa'}
                        variant={'outlined'}
                        onChange={(e) => setJustificationValue(e.target.value)}
                        error={!justificationValue}
                      />
                    </Box>
                  )}

                  <Button variant={'outlined'} onClick={handleRefusePayment}>
                    Recusar Pagamento
                  </Button>
                  <Button variant={'contained'} onClick={handleConfirmPayment}>
                    Confirmar Pagamento
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </ModalWrapper>

        <ModalWrapper
          title={'Visualizar Despesa'}
          showModal={selectedExpense.show}
          closeModalAction={() =>
            setSelectedExpense({ value: selectedExpense.value, show: false })
          }
        >
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Box display={'flex'} gap={2}>
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                Centro de custo:
              </span>
              {selectedExpense.value?.costCenter}
            </Box>
            <Box display={'flex'} gap={2}>
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                Meio de pgto:
              </span>
              {selectedExpense.value?.paymentType}
            </Box>
            <Box display={'flex'} gap={2}>
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                Projeto:
              </span>
              {selectedExpense.value?.project}
            </Box>
            <Box display={'flex'} gap={2}>
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                Motivo:
              </span>
              {selectedExpense.value?.reason}
            </Box>
            <Box display={'flex'} gap={2}>
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                Justificativa:
              </span>
              {selectedExpense.value?.justification}
            </Box>
          </Box>
        </ModalWrapper>
      </Container>
    </>
  );
};

export default FinancialManagement;
