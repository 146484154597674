import {
  Container,
  Grid,
  MenuItem,
  Typography,
  TextField,
  InputLabel,
  Box,
  Button,
  CircularProgress,
  Tooltip,
  IconButton,
  Autocomplete  
} from '@mui/material';
import ResponsiveAppBar from '../../NavBar';
import { useForm, Controller, useWatch } from 'react-hook-form';
import FormSelect from '../../FormSelect';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import FormInput from '../../FormInput';
import { useEffect, useMemo, useState } from 'react';
import Footer from '../../Footer';
import { useDispatch, useSelector } from 'react-redux';
import { GetExpenseTypes } from '../../../store/_Entities/ExpenseType';
import { GetReportsAvailable } from '../../../store/_Entities/ExpensesReport';
import { GetCostCenters } from '../../../store/_Entities/CostCenter';
import { GetPaymentTypes } from '../../../store/_Entities/PaymentType';
import { GetReasonsByCompanyId } from '../../../store/_Entities/Reason';
import { GetProjects } from '../../../store/_Entities/Project';
import { GetCurrency } from '../../../store/_Entities/Currency';
import { inputformatNumber } from '../../../utils/format';
import {
  CreateExpense,
  EditExpense,
  GetEditExpense,
  GetExpenseAttachs,
  GetFields,
  RemoveExpenseAttach,
} from '../../../store/_Entities/Expenses';
import { useNavigate, useParams } from 'react-router-dom';
import ModalErrorSuccess from '../../ModalErrorSuccess';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const ExpensesForm = () => {
  const { expenseID } = useParams();
  const {
    user,
    expenses,
    expenseType,
    expensesReport,
    costCenter,
    paymentType,
    reason,
    project,
    currency,
  } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const [fields, setFields] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const isLoading = [
    expenseType.loading,
    expenses.loading,
    expensesReport.loading,
    costCenter.loading,
    paymentType.loading,
    reason.loading,
    project.loading,
    currency.loading,
  ].some(Boolean);

  const [filesPreview, setFilesPreview] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      file: '',
      expenseTypeID: '',
      reportID: '',
      costCenterID: '',
      paymentTypeID: '',
      reasonID: '',
      reasonDescription: '',
      justification: '',
      projectID: '',
      projectDescription: '',
      currency: '',
      date: '',
      price: '',
      description: '',
    },
  });

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setFilesPreview((prev) => [...prev, ...files]);
    setValue('file', files);
  };

  const handleRemoveFile = (index) => {
    const file = filesPreview[index];
    if (file?.expenseAttachID) {
      dispatch(RemoveExpenseAttach(file.expenseAttachID));
    }
    setFilesPreview((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setValue(
      'file',
      filesPreview.filter((_, i) => i !== index)
    );
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    if (data.file.length > 0) {
      data.file.forEach((file) => {
        formData.append('file', file);
      });
    }
    formData.append('expenseTypeID', data.expenseTypeID);
    formData.append('reportID', data.reportID);
    formData.append('costCenterID', data.costCenterID);
    formData.append('paymentTypeID', data.paymentTypeID);
    if (fields.includes(1)) {
      formData.append('projectID', data.projectID);
      formData.append(
        'projectDescription',
        project.projects.find((project) => project.projectID == data.projectID)
          .description
      );
    }
    if (fields.includes(2)) {
      formData.append('reasonID', data.reasonID);
      formData.append(
        'reasonDescription',
        reason.reasons.find((reason) => reason.reasonID == data.reasonID)
          .description
      );
    }
    if (fields.includes(3)) {
      formData.append('justification', data.justification);
    }
    formData.append('currency', data.currency);
    formData.append('date', data.date.toLocaleDateString('pt-BR'));
    formData.append('price', inputformatNumber(data.price));
    formData.append('description', data.description);

    if (expenseID) {
      dispatch(EditExpense(expenseID, formData));
    } else {
      dispatch(CreateExpense(formData));
    }

    setHasSubmitted(true);
  };

  useEffect(() => {
    const userCompanyID = user.user.companyID;
    dispatch(GetExpenseTypes(userCompanyID));
    dispatch(GetReportsAvailable(userCompanyID));
    dispatch(GetCostCenters(userCompanyID));
    dispatch(GetPaymentTypes(userCompanyID));
    dispatch(GetCurrency(userCompanyID));

    //Para edit realizar a busca dos campos? ou apenas retorna a chamada de getEdit
    dispatch(GetFields());

    if (expenseID) {
      dispatch(GetEditExpense(expenseID));
      dispatch(GetExpenseAttachs(expenseID));
    }
    setHasFetched(true);
  }, [user.user.companyID, expenseID]);

  useEffect(() => {
    if (expenses.fields.length > 0 && hasFetched) {
      expenses.fields.forEach((field) => {
        if (field.fieldID === 1) {
          dispatch(GetProjects(user.user.companyID));
          setFields((prev) => [...prev, field.fieldID]);
        }
        if (field.fieldID === 2) {
          dispatch(GetReasonsByCompanyId(user.user.companyID));
          setFields((prev) => [...prev, field.fieldID]);
        }
        if (field.fieldID === 3) {
          setFields((prev) => [...prev, field.fieldID]);
        }
      });
    }
  }, [expenses.fields]);

  useEffect(() => {
    if (expenseID && expenses.selectedExpense && !expenses.loading) {
      const selectedExpense = expenses.selectedExpense;

      const [day, month, year] = selectedExpense?.date
        ?.substring(0, 10)
        .split('/') || [null, null, null];
      const dateObject = new Date(year, month - 1, day);

      setValue('expenseTypeID', selectedExpense.expenseTypeID);
      setValue('costCenterID', selectedExpense.costCenterID);
      setValue('paymentTypeID', selectedExpense.paymentTypeID);
      setValue('reasonID', selectedExpense.reason);
      setValue('justification', selectedExpense.justification);
      setValue('currency', selectedExpense.currency);
      setValue('date', dateObject);
      setValue('projectID', selectedExpense.project);
      setValue('reportID', selectedExpense.reportID);

      setValue('price', selectedExpense.price);
      setValue('description', selectedExpense.description);
      setFilesPreview(expenses.expenseAttachs);
    }
  }, [expenses.selectedExpense]);

  useEffect(() => {
    if (hasSubmitted && !expenses.loading && expenses.success) {
      setShowModal(true);
    }
  }, [expenses]);

  const selectedReport = useWatch({ control, name: 'reportID' });
  const selectedPrice = useWatch({ control, name: 'price' });
  const convertedPrice = useMemo(() => {
    if (selectedPrice) {
      return parseFloat(selectedPrice.replace(/\./g, '').replace(',', '.'));
    }
    return 0;
  }, [selectedPrice]);

  const report = expensesReport.reports.find(
    (report) => report.reportID === selectedReport
  );

  const reportAvailableValue = useMemo(() => report?.advancedAmount, [report]);
  const reportValue = useMemo(() => report?.value, [report]);

  return (
    <>
      <ResponsiveAppBar />
      <Container sx={{ mt: 6 }}>
        {isLoading ? (
          <Box textAlign={'center'}>
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={2}
              sx={{
                boxShadow: '0px 0px 4px 0px #00000040',
                borderRadius: '10px',
                padding: 2,
              }}
            >
              <Grid item xs={12} textAlign={'center'}>
                <Typography variant="h5">Cadastro de Despesa</Typography>
                <Box
                  display={'flex'}
                  width={'100%'}
                  justifyContent={'center'}
                  gap={2}
                >
                  {reportAvailableValue !== 0 &&
                    reportAvailableValue !== undefined && (
                      <Typography variant="caption" color={'error'}>
                        Valor Disponível: {reportAvailableValue}
                      </Typography>
                    )}

                  {reportValue !== 0 && reportValue !== undefined && (
                    <Typography variant="caption">
                      Valor: {reportValue}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  id="file-upload"
                  accept=".xls,.xlsx,application/pdf,image/png,image/jpeg,image/jpg"
                />
                <Grid item xs={12} alignItems={'center'}>
                  <Box
                    display="flex"
                    justifyContent={'center'}
                    flexWrap={'wrap'}
                    gap={2}
                  >
                    {filesPreview.map((file, index) => (
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        gap={1}
                        key={index}
                      >
                        <IconButton onClick={() => handleRemoveFile(index)}>
                          <HighlightOffIcon color="primary" />
                        </IconButton>
                        <Box
                          key={index}
                          display="flex"
                          alignItems="center"
                          flexDirection={'column'}
                          gap={1}
                        >
                          <Box>
                            {file?.type?.startsWith('image/') ||
                            file?.fileName?.toLowerCase()?.endsWith('.png') ||
                            file?.fileName?.toLowerCase()?.endsWith('.jpg') ? (
                              <img
                                src={
                                  file?.expenseAttachID
                                    ? file?.fileURL
                                    : file instanceof File
                                    ? URL.createObjectURL(file)
                                    : file?.fileURL
                                }
                                alt={file.name}
                                style={{
                                  width: 50,
                                  height: 50,
                                  objectFit: 'cover',
                                  borderRadius: 4,
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  if (file?.fileURL)
                                    window.open(file?.fileURL, '_blank');
                                }}
                              />
                            ) : (
                              <InsertDriveFileIcon
                                sx={{ fontSize: 50, cursor: 'pointer' }}
                                color="primary"
                                onClick={() => {
                                  if (file?.fileURL)
                                    window.open(file?.fileURL, '_blank');
                                }}
                              />
                            )}
                          </Box>

                          <Tooltip title={file.name}>
                            <span>
                              {file?.name &&
                                (file.name.length > 10
                                  ? `${file.name.slice(0, 10)}...`
                                  : file.name)}
                            </span>
                          </Tooltip>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <Box display={'flex'} alignItems={'center'} gap={2}>
                    <Box>
                      <InputLabel>Recibo:</InputLabel>
                    </Box>
                    <Box
                      display={'flex'}
                      gap={2}
                      width={'100%'}
                      justifyContent={'space-between'}
                    >
                      <label htmlFor="file-upload">
                        <Button variant="contained" component="span">
                          Selecionar arquivos
                        </Button>
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="expenseTypeID"
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  render={({ field }) => (
                    <FormSelect
                      field={field}
                      errors={errors.expenseTypeID}
                      label={'Tipo de Despesa'}
                    >
                      {expenseType?.expenses.map((expense) => (
                        <MenuItem
                          key={expense.expenseTypeID}
                          value={expense.expenseTypeID}
                        >
                          {expense.description}
                        </MenuItem>
                      ))}
                    </FormSelect>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="reportID"
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  render={({ field }) => (
                    <FormSelect
                      field={field}
                      errors={errors.reportID}
                      label={'Relatório'}
                    >
                      {expensesReport.reports.map((report) => {
                        if (
                          report.reportStatusID === 1 ||
                          report.reportStatusID === 4 ||
                          report.reportStatusID === 8 ||
                          report.reportStatusID === 9
                        )
                          return (
                            <MenuItem
                              key={report.reportID}
                              value={report.reportID}
                            >
                              {report.description}
                            </MenuItem>
                          );
                      })}
                    </FormSelect>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="costCenterID"
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  render={({ field }) => (
                    <Box>
                      <label htmlFor="costCenterID">Centro de Custo</label>
                      <Autocomplete
                        id="costCenterID"
                        fullWidth
                        options={costCenter.costCenters}
                        getOptionLabel={(option) =>
                          `${option.costCenterID} - ${option.number} - ${option.description}`
                        }
                        renderOption={(props, option) => {
                          const { key, ...optionProps } = props;
                          return (
                            <Box key={key} {...optionProps}>
                              {option.number} - {option.description}
                            </Box>
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Selecione..."
                            error={!!errors?.costCenterID}
                            helperText={errors?.costCenterID?.message}
                          />
                        )}
                        onChange={(_, newValue) =>
                          field.onChange(newValue?.costCenterID || '')
                        }
                        value={
                          costCenter.costCenters.find(
                            (c) => c.costCenterID === field.value
                          ) || null
                        }
                        filterOptions={(options, params) => {
                          const { inputValue } = params;

                          const filtered = options.filter((option) =>
                            String(`${option.number} - ${option.description}`).toUpperCase().includes(inputValue.toUpperCase())
                          );

                          return filtered;
                        }}
                      />
                    </Box>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="paymentTypeID"
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  render={({ field }) => (
                    <FormSelect
                      field={field}
                      errors={errors.paymentTypeID}
                      label={'Tipo de Pagamento'}
                    >
                      {paymentType.paymentTypes.map((paymentType) => (
                        <MenuItem
                          key={paymentType.paymentTypeID}
                          value={paymentType.paymentTypeID}
                        >
                          {paymentType.description}
                        </MenuItem>
                      ))}
                    </FormSelect>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="currency"
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  render={({ field }) => (
                    <FormSelect
                      field={field}
                      errors={errors.currency}
                      label={'Moeda'}
                    >
                      {currency.currencies.map((currency) => (
                        <MenuItem
                          key={currency.currencyCode}
                          value={currency.currencyCode}
                        >
                          {currency.currencyCode} - {currency.name}
                        </MenuItem>
                      ))}
                    </FormSelect>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="date"
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  render={({ field }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={ptBR}
                    >
                      <InputLabel>Data</InputLabel>
                      <DatePicker
                        value={field.value}
                        empty
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            size="small"
                            fullWidth
                            error={Boolean(errors.date)}
                          />
                        )}
                        onChange={(value) => field.onChange(value)}
                      />
                      {errors.date && (
                        <Typography variant="caption" color="error">
                          {errors.date.message}
                        </Typography>
                      )}
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="price"
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  render={({ field }) => {
                    const [inputValue, setInputValue] = useState(field.value);

                    useEffect(() => {
                      setInputValue(inputformatNumber(field.value));
                    }, [field.value]);

                    const handleChange = (e) => {
                      const formattedValue = inputformatNumber(e.target.value);
                      setInputValue(formattedValue);
                      field.onChange(formattedValue);
                    };
                    return (
                      <Box>
                        <InputLabel htmlFor="price">Valor</InputLabel>
                        <TextField
                          id="price"
                          type="text"
                          variant="outlined"
                          value={inputValue}
                          placeholder={'Digite...'}
                          fullWidth
                          error={Boolean(errors.price)}
                          onChange={handleChange}
                        />
                        {errors.price && (
                          <Typography variant="caption" color="error">
                            {errors.price.message}
                          </Typography>
                        )}
                      </Box>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  render={({ field }) => (
                    <FormInput
                      field={field}
                      errors={errors.description}
                      label={'Descrição'}
                    />
                  )}
                />
              </Grid>
              {fields.includes(1) && (
                <Grid item xs={12} md={4}>
                  <Controller
                    name="projectID"
                    control={control}
                    rules={{ required: 'Campo obrigatório' }}
                    render={({ field }) => (
                      <FormSelect
                        field={field}
                        errors={errors.projectID}
                        label={'Projeto'}
                      >
                        {project.projects.map((project) => (
                          <MenuItem
                            key={project.projectID}
                            value={project.projectID}
                          >
                            {project.number} - {project.description}
                          </MenuItem>
                        ))}
                      </FormSelect>
                    )}
                  />
                </Grid>
              )}
              {fields.includes(2) && (
                <Grid item xs={12} md={4}>
                  <Controller
                    name="reasonID"
                    control={control}
                    rules={{ required: 'Campo obrigatório' }}
                    render={({ field }) => (
                      <FormSelect
                        field={field}
                        errors={errors.reasonID}
                        label={'Motivo'}
                      >
                        {reason.reasons.map((reason) => (
                          <MenuItem
                            key={reason.reasonID}
                            value={reason.reasonID}
                          >
                            {reason.description}
                          </MenuItem>
                        ))}
                      </FormSelect>
                    )}
                  />
                </Grid>
              )}

              {fields.includes(3) && (
                <Grid item xs={12} md={4}>
                  <Controller
                    name="justification"
                    control={control}
                    rules={{ required: 'Campo obrigatório' }}
                    render={({ field }) => (
                      <FormInput
                        field={field}
                        errors={errors.justification}
                        label={'Justificativa'}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Box display={'flex'} justifyContent={'end'} gap={2} mt={2}>
              <Button variant="contained" type="submit">
                Salvar
              </Button>
              <Button variant="outlined" href="/expen/despesas">
                Cancelar
              </Button>
            </Box>
          </form>
        )}

        <Footer />
      </Container>
      <ModalErrorSuccess
        show={showModal}
        handleClose={() => navigate('/expen/despesas')}
        title={'Sucesso!'}
      >
        <Box display={'flex'} textAlign={'center'} flexDirection={'column'}>
          <Typography variant="body1">
            Despesa cadastrada com sucesso.
          </Typography>
          <Typography variant="body1">
            Restante no relatorio :{' '}
            {expenseID
              ? Math.abs(
                  reportAvailableValue +
                    +expenses.selectedExpense.price -
                    convertedPrice
                ).toFixed(2)
              : Math.abs(reportAvailableValue - convertedPrice).toFixed(2)}
          </Typography>
        </Box>
      </ModalErrorSuccess>
    </>
  );
};

export default ExpensesForm;
