import { useState } from 'react';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { Box, CircularProgress } from '@mui/material';

export const CustomDataGrid = ({
  rows,
  columns,
  height,
  width,
  props,
  noRowsText,
  dataGridInitialState,
  miniature,
  pagNum,
  pagSize,
  totalCount,
  onChangePagNum,
  onChgangePagSize
}) => {
  const [pageSize, setPageSize] = useState(25);
  const isTiny = rows.length < 7;
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  const handleColumnVisibilityChange = (newVisibilityModel) => {
    setColumnVisibilityModel(newVisibilityModel);
  };

  const handlePageChange = (pagNum) => {
    if(onChangePagNum) {
      onChangePagNum(pagNum)
    };
  };

  const handlePageSizeChange = (pagSize) => {
    if(onChgangePagSize) onChgangePagSize(pagSize);
    setPageSize(pagSize)
  };

  return (
    <Box
      height={height ?? (miniature ? '400px' : isTiny ? '400px' : '800px')}
      width={width}
      sx={{
        '& .color-recusado': {
          backgroundColor: '#EE7330',
          '&:hover': {
            backgroundColor: '#D46021',
          },
        },
      }}
    >
      <DataGrid
        initialState={dataGridInitialState}
        // autoHeight={!isTiny}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        rowCount={totalCount}
        pageSize={pagSize ?? pageSize}
        page={pagNum}
        paginationMode={(!!pagNum && !!pagSize)? "server":"client"}
        onPageChange={num => handlePageChange(num)}
        onPageSizeChange={newPageSize => handlePageSizeChange(newPageSize)}
        rowsPerPageOptions={[25, 50, 100]}
        columnBuffer={50}
        getRowClassName={(params) => {
          if (params.row.total) {
            return 'total-row';
          }
          return '';
        }}
        components={{
          NoRowsOverlay: () => (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              {noRowsText
                ? 'Nenhum dado disponível'
                : 'Selecione filtro para exibir resultados.'}
            </Box>
          ),
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleColumnVisibilityChange}
        {...props}
        sx={{
          '& .MuiSvgIcon-root': { color: '#EE7330' },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#D9D9D9',
          },
          '& .MuiDataGrid-main': {
            borderRadius: '5px',
            boxShadow: '0px 4px 4px 0px #00000040',
          },
          '& .MuiDataGrid-iconSeparator': { display: 'none' },
          '.total-row': {
            backgroundColor: '#EE7330',
            color: 'white',
          },
        }}
      />
    </Box>
  );
};

export default CustomDataGrid;
