import { Box, Container, Grid, Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerRange from '../../../../components/DatePickerRange';
import HeaderSearchSelectMultiple from '../../../../components/HeaderSearchSelectMultiple';
import HeaderSelect from '../../../../components/HeaderSelect';
import ResponsiveAppBar from '../../../../components/NavBar';
import CustomTable from '../../../../components/Table/CustomTable';

import {
  GetCompletedRacesReport,
  GetCompletedRacesReportByUser,
} from '../../../../store/_Entities/CompletedRacesReport';
import { GetCostCenters } from '../../../../store/_Entities/CostCenter';
import { GetAllProviders } from '../../../../store/_Entities/Provider';
import validateDate from '../../../../utils/validateDate';
import HeaderSelectSearchable from '../../../../components/HeaderSelectSearchable';

const CompletedRaces = () => {
  const { user, company, costCenter, provider, completedRacesReport } =
    useSelector((state) => state.entities);
  const dispatch = useDispatch();

  const userID = user.authData.sub;

  const [companiesInputValue, setCompaniesInputValue] = useState([]);

  const [selectedCostCenter, setSelectedCostCenter] = useState('');
  const [selectProviderID, setSelectProviderID] = useState(null);

  const [selectedRides, setSelectedRides] = useState(null);
  const [data, setData] = useState([]);
  const [costCenters, setCostCenters] = useState([]);

  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); //Data 7 dias atrás
  const [endDate, setEndDate] = useState(new Date());

  const userRoles = user.roles;
  const dispatchUserRides =
    userRoles.includes('Driver') &&
    !userRoles.includes('systemadministrator') &&
    !userRoles.includes('costcenteradministrator') &&
    !userRoles.includes('companyadministrator');

  const fetchReports = () => {
    const startDateFormatted = startDate.toLocaleDateString('pt-BR');
    const endDateFormatted = endDate.toLocaleDateString('pt-BR');
    const providerID =
      selectProviderID === null || selectProviderID === 'null'
        ? null
        : selectProviderID.toString();
    const costCenterID = selectedCostCenter || 0;

    const request = dispatchUserRides
      ? {
          companyID: user.user.companyID,
          costCenterID: costCenterID,
          startDate: startDateFormatted,
          endDate: endDateFormatted,
          corridaRealizada: selectedRides || null,
          providerID: providerID,
        }
      : {
          companyID: 0,
          startDate: startDateFormatted,
          endDate: endDateFormatted,
          costCenterID,
          providerID,
          corridaRealizada: selectedRides || 'null',
          companies: companiesInputValue.length
            ? companiesInputValue.map((u) => u.companyID)
            : [0],
        };

    if (dispatchUserRides) {
      dispatch(GetCompletedRacesReportByUser(request));
    } else {
      dispatch(GetCompletedRacesReport(request));
    }
  };

  useEffect(() => {
    dispatch(GetAllProviders());
  }, []);

  useEffect(() => {
    if (companiesInputValue.length > 0) {
      dispatch(GetCostCenters(companiesInputValue[0].companyID));
    }
  }, [companiesInputValue]);

  useEffect(() => {
    if (
      companiesInputValue.length > 0 &&
      costCenter.costCenters &&
      costCenter.success
    ) {
      setCostCenters(costCenter.costCenters);
    }
  }, [companiesInputValue]);

  useEffect(() => {
    if (
      (companiesInputValue.length > 0 || dispatchUserRides) &&
      validateDate(startDate) &&
      validateDate(endDate)
    ) {
      fetchReports();
    }
  }, [
    companiesInputValue,
    selectedCostCenter,
    startDate,
    endDate,
    selectProviderID,
    selectedRides,
  ]);

  useEffect(() => {
    if (
      (dispatchUserRides || companiesInputValue.length > 0) &&
      completedRacesReport?.completedRacesReports &&
      !completedRacesReport.loading &&
      completedRacesReport.success
    ) {
      console.log(completedRacesReport?.completedRacesReports);
      setData(
        dispatchUserRides
          ? completedRacesReport.completedRacesReports
          : completedRacesReport.completedRacesReports.result
      );
    }
  }, [completedRacesReport.completedRacesReports]);

  const columns = useMemo(
    () => [
      {
        field: 'rideID',
        headerName: 'ID da Corrida',
        headerAlign: 'center',
        align: 'start',
        minWidth: 110,
        flex: 1,
      },
      {
        field: 'company',
        headerName: 'Empresa',
        headerAlign: 'center',
        align: 'start',
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'userID',
        headerName: 'ID do Usuário',
        headerAlign: 'center',
        align: 'start',
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'name',
        headerName: 'Nome',
        headerAlign: 'center',
        align: 'start',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'email',
        headerName: 'E-mail',
        headerAlign: 'center',
        align: 'start',
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'phone',
        headerName: 'Telefone',
        headerAlign: 'center',
        align: 'start',
        minWidth: 165,
        flex: 1,
      },
      {
        field: 'costCenterAdm',
        headerName: 'Gestor do Centro de Custo',
        headerAlign: 'center',
        align: 'start',
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'costCenter',
        headerName: 'Centro de Custo',
        headerAlign: 'center',
        align: 'start',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'supervisionGroup',
        headerName: 'Centro de Supervisão',
        headerAlign: 'center',
        align: 'start',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'startDate',
        headerName: 'Data da Corrida',
        headerAlign: 'center',
        align: 'start',
        minWidth: 150,
        flex: 1,
        valueFormatter: (params) => {
          const date = new Date(params.value);
          return date.toLocaleString('pt-BR', {
            dateStyle: 'short',
            timeStyle: 'short',
          });
        },
      },
      {
        field: 'startAddress',
        headerName: 'Origem',
        headerAlign: 'center',
        align: 'start',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'endAddress',
        headerName: 'Destino',
        headerAlign: 'center',
        align: 'start',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'duration',
        headerName: 'Duração (min)',
        headerAlign: 'center',
        align: 'start',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'distancy',
        headerName: 'Distância (km)',
        headerAlign: 'center',
        align: 'start',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'provider',
        headerName: 'Fornecedor',
        headerAlign: 'center',
        align: 'start',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'category',
        headerName: 'Categoria',
        headerAlign: 'center',
        align: 'start',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'product',
        headerName: 'Produto',
        headerAlign: 'center',
        align: 'start',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'estimated',
        headerName: 'Preço Estimado',
        headerAlign: 'center',
        align: 'start',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'price',
        headerName: 'Preço Pago',
        headerAlign: 'center',
        align: 'start',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'driver',
        headerName: 'Motorista',
        headerAlign: 'center',
        align: 'start',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'car',
        headerName: 'Carro',
        headerAlign: 'center',
        align: 'start',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'plate',
        headerName: 'Placa',
        headerAlign: 'center',
        align: 'start',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'transactionID',
        headerName: 'ID da Transação',
        headerAlign: 'center',
        align: 'start',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'justification',
        headerName: 'Justificativa',
        headerAlign: 'center',
        align: 'start',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'reason',
        headerName: 'Motivo',
        headerAlign: 'center',
        align: 'start',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'performedType',
        headerName: 'Corrida Realizada',
        headerAlign: 'center',
        align: 'start',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'method',
        headerName: 'Método Pago',
        headerAlign: 'center',
        align: 'start',
        minWidth: 150,
        flex: 1,
      },
    ],
    []
  );

  const exportHeaders = useMemo(
    () => ({
      rideID: 'ID da Corrida',
      company: 'Empresa',
      userID: 'ID do Usuário',
      name: 'Nome',
      email: 'E-mail',
      phone: 'Telefone',
      costCenterAdm: 'Gestor do Centro de Custo',
      costCenter: 'Centro de Custo',
      supervisionGroup: 'Centro de Supervisão',
      startDate: 'Data da Corrida',
      startAddress: 'Origem',
      endAddress: 'Destino',
      duration: 'Duração (min)',
      distancy: 'Distância (km)',
      provider: 'Fornecedor',
      category: 'Categoria',
      product: 'Produto',
      estimated: 'Preço Estimado',
      price: 'Preço Pago',
      driver: 'Motorista',
      car: 'Carro',
      plate: 'Placa',
      transactionID: 'ID da Transação',
      justification: 'Justificativa',
      reason: 'Motivo',
      performedType: 'Corrida Realizada',
      method: 'Método Pago',
    }),
    []
  );

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black',
            }}
          >
            <h2>Corridas Realizadas</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                flexWrap: 'wrap',
              }}
            >
              <Grid container gap={4} alignItems="center">
                {!dispatchUserRides && (
                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <HeaderSearchSelectMultiple
                      label="Empresas"
                      onChange={(e) => {
                        setCompaniesInputValue(e.target.value);
                      }}
                      inputValue={companiesInputValue}
                      menuItems={company?.companies
                        ?.filter((company) => company.active == 1)
                        .sort((companyA, companyB) =>
                          companyA.giveName.localeCompare(companyB.giveName)
                        )
                        .map((company) => {
                          return { label: company.giveName, value: company };
                        })}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  {/*                   <HeaderSelect
                    label="Centro de Custo"
                    onChange={setSelectedCostCenter}
                    menuItems={[
                      { label: 'TODOS', value: '0' },
                      ...(costCenters.map((costCenter) => ({
                        label: costCenter.description,
                        value: costCenter.costCenterID,
                      })) || []),
                    ]}
                    defaultValue={undefined}
                  /> */}
                  <HeaderSelectSearchable
                    label="Centro de Custo"
                    onChange={setSelectedCostCenter}
                    menuItems={[
                      { label: 'TODOS', value: '0' },
                      ...(costCenters.map((costCenter) => ({
                        label:
                          costCenter.number + ' - ' + costCenter.description,
                        value: costCenter.costCenterID,
                      })) || []),
                    ]}
                    error={undefined}
                    defaultValue={undefined}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <HeaderSelect
                    label="Fornecedor"
                    onChange={(event) => {
                      setSelectProviderID(event.target.value);
                    }}
                    menuItems={[
                      {
                        label: 'TODOS',
                        value: 'null',
                      },
                      ...(provider?.providers?.map((prov) => ({
                        label: prov.description,
                        value: prov.providerID,
                      })) || []),
                    ]}
                    defaultValue={undefined}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <HeaderSelect
                    label="Corridas realizadas"
                    onChange={(event) => {
                      setSelectedRides(event.target.value);
                    }}
                    menuItems={[
                      { label: 'Todas', value: 'null' },
                      { label: 'Por Fora', value: '0' },
                      { label: 'Por Dentro', value: '1' },
                    ]}
                    defaultValue={'null'}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} sx={{ mt: 2 }}>
                <DatePickerRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={{ startDate: setStartDate, endDate: setEndDate }}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.transactionID}
          exportHeaders={exportHeaders}
          loading={completedRacesReport.loading}
        />
      </Container>
    </>
  );
};

export default CompletedRaces;
