import { useState, useEffect } from 'react';
import ResponsiveAppBar from '../../../components/NavBar';
import Header from '../../../components/Header';
import { Container, Grid, Box, Button, CircularProgress } from '@mui/material';
import HeaderSearchSelect from '../../../components/HeaderSearchSelect';
import DatePickerRange from '../../../components/DatePickerRange';
import HeaderSearchSelectCompany from '../../../components/HeaderSearchSelectCompany';
import { useDispatch, useSelector } from 'react-redux';
import { GetListExpensesByProject, GetDownloadAttachs, EXPENSES_CLEAR_DOWNLOAD } from '../../../store/_Entities/ListExpenses';
import { GetProjects } from '../../../store/_Entities/Project';
import { format } from 'date-fns';
import documentExcel from '../../../assets/images/excel.png';
import axios from 'axios';
import { exportFile } from '../../../utils/download';
import { SHOW_SNACK } from "../../../store/_UI/Snackbar";

const exportHeadersLog = {
  company: 'Empresa',
  costCenter: 'Centro de Custo',
  project: 'Projeto',
  userID: 'UserID',
  user: 'Nome',
  registration: 'Matrícula',
  expenseType: 'Tipo despesa',
  description: 'Descrição',
  reason: 'Motivo',
  justification: 'Justificativa',
  report: 'Relatório',
  dateDisplay: 'Data',
  price: 'Valor',
  currency: 'Moeda',
  paymentType: 'Tipo de Pgto'
};

const ProjectReport = () => {
  const { project, listExpenses } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();
  const [projectList, setProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [projectInput, setProjectInput] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [isLoadingDownload, setIsLoadingDownload] = useState('');

  useEffect(() => {
    setProjectList([]);
    setProjectInput('');
    setSelectedProject('');

    if (selectedCompany === '') return;

    dispatch(GetProjects(selectedCompany));
    return;
  }, [selectedCompany]);

  useEffect(() => {
    if (project.projects.length > 0) {
      setProjectList(project.projects);
    }
    return;
  }, [project.projects]);

  useEffect(() => {
    if(listExpenses.error) {
      setIsLoadingDownload('none');
      dispatch(SHOW_SNACK({message: 'Nenhum anexo encontrado', severity: "warning"}));
    }
    return;
  },[listExpenses.error])

  useEffect(() => {
    if (listExpenses.downloadFile) {
      const file = listExpenses.downloadFile;

      const byteCharacters = atob(file);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {type: 'application/vnd.ms-excel'});
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', 'Anexos.zip');
      document.body.appendChild(link);
      link.click();

      setIsLoadingDownload('none');
      dispatch(EXPENSES_CLEAR_DOWNLOAD());
    }
    return;
  }, [listExpenses.downloadFile]);

  useEffect(() => {
    if (selectedCompany === '' || startDate === null || endDate === null || selectedProject === '') return;

    const startDateFormatted = startDate.toLocaleDateString('pt-BR');
    const endDateFormatted = endDate.toLocaleDateString('pt-BR');
    const request = {
      CompanyID: selectedCompany,
      StartDate: startDateFormatted,
      EndDate: endDateFormatted,
      ProjectID: selectedProject,
    };

    dispatch(GetListExpensesByProject(request))
    
    return;
  }, [selectedCompany, startDate, endDate, selectedProject]);

  useEffect(() => {
    if (selectedCompany === '' || startDate === null || endDate === null || selectedProject === '') return;

    dispatch(SHOW_SNACK({message: `Foram localizados ${listExpenses.expenseReports.length} registros`, severity: "success"}));

    return;
  }, [listExpenses.expenseReports]);

  const handleDownloadPdf = async () => {
    const isValid = await validadeFields();
    if(!isValid) return;

    setIsLoadingDownload('pdf');

    const data = {
      tasks: {
        'task-1': {
          operation: 'capture-website',
          url: `https://wexp-exponential.azurewebsites.net/Invoice/ExpensesProjectLayout?CompanyID=${selectedCompany}&StartDate=${format(startDate,'dd/MM/yyyy')}&EndDate=${format(endDate,'dd/MM/yyyy')}&ProjectID=${selectedProject}`,
          output_format: 'pdf',
          engine: 'chrome',
          zoom: 1,
          page_width: 50,
          page_height: 50,
          print_media_type: true,
          margin_top: 10,
          margin_bottom: 10,
          margin_left: 10,
          margin_right: 10,
          print_background: true,
          display_header_footer: false,
          wait_until: 'load',
          wait_time: 0,
          filename: 'Adiantamento de Despesas.pdf',
        },
        'export-1': {
          operation: 'export/url',
          input: ['task-1'],
          inline: false,
          archive_multiple_files: false,
        }
      }
    };

    try {
      const createJobResponse = await axios.post(
        'https://api.cloudconvert.com/v2/jobs',
        data,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CLOUD_CONVERT_TOKEN} `,
            'Content-Type': 'application/json',
          },
        }
      );

      const id = createJobResponse.data.data.id;

      await axios
        .get(`https://api.cloudconvert.com/v2/jobs/${id}/wait`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CLOUD_CONVERT_TOKEN} `,
          },
        })
        .then((waitResponse) => {
          const fileUrl = waitResponse.data.data.tasks[0].result.files[0].url;
          window.open(fileUrl, '_blank');
        });
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoadingDownload('none');
    }
  };
																		
  const handleExportExcel = async() => {
    const isValid = await validadeFields();
    if(!isValid) return;

    setIsLoadingDownload('excel');

    try{
      exportFile(exportHeadersLog, listExpenses.expenseReports, 'xls', `Relatório de Despesas por Projeto`);Q
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoadingDownload('none');
    }
  }

  const handleExportAttachs = async() => {
    const isValid = await validadeFields();
    if(!isValid) return;
    
    setIsLoadingDownload('attachs');

    try{
      const startDateFormatted = startDate.toLocaleDateString('pt-BR');
      const endDateFormatted = endDate.toLocaleDateString('pt-BR');
      const request = {
        CompanyID: selectedCompany,
        StartDate: startDateFormatted,
        EndDate: endDateFormatted,
        ProjectID: selectedProject,
      };

      const type = dispatch(GetDownloadAttachs(request));
    } catch (error) {
      console.error('Error:', error);
      setIsLoadingDownload('none');
    }
  }

  const validadeFields = () => {
    let message = '';

    if (selectedCompany === '') {
      message = 'Selecione uma empresa';
    }

    if (selectedProject === '' && message === '') {
      message = 'Selecione um projeto';
    }

    if (startDate === null && message === '') {
      message = 'Selecione a data de início';
    }

    if (endDate === null && message === '') {
      message = 'Selecione a data de fim';
    }

    if(message.length) dispatch(SHOW_SNACK({message: message, severity: "warning"}));

    return message.length === 0;
  }

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Header title="Relatório de Despesas por Projeto">
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={12} md={4}>
              <HeaderSearchSelectCompany
                label="Empresas"
                onChange={setSelectedCompany}
                defaultValue={selectedCompany}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <HeaderSearchSelect
                label={'Projeto'}
                onChange={setSelectedProject}
                inputValue={projectInput}
                inputChange={setProjectInput}
                menuItems={projectList.map((item) => {
                  return {
                    value: item.projectID,
                    label: item.description,
                  };
                })}
                disabled={selectedCompany === ''}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <DatePickerRange
                startDate={startDate}
                endDate={endDate}
                onChange={{ startDate: setStartDate, endDate: setEndDate }}
              />
            </Grid>
          </Grid>
        </Header>
        <Grid container spacing={2} alignItems={'center'}>
          <Box gap={2} ml={'auto'} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
            Exportar
            <Box
              p={'4px'}
              sx={{
                display: `flex`,
                boxShadow: '0px 0px 4px 0px #00000040',
                borderRadius: '10px',
              }}
            >
              <Button
                variant="text"
                sx={{
                  color: 'black',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '3px',
                  textTransform: 'none',
                }}
                onClick={handleExportExcel}
                disabled={isLoadingDownload === 'excel'}
              >
                {isLoadingDownload === 'excel' ? 
                  <CircularProgress size={12}/>
                  : 
                  <img src={documentExcel} alt="Excel" style={{ width: '20px' }} />
                }
                <span style={{ textDecoration: 'underline' }}>Gerar excel</span>
              </Button>
              <Button
                variant="text"
                sx={{
                  color: 'black',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '3px',
                  textTransform: 'none',
                }}
                disabled={isLoadingDownload === 'pdf'}
                onClick={handleDownloadPdf}
              >
                {isLoadingDownload === 'pdf' ? 
                  <CircularProgress size={12}/>
                  : 
                  <img src={documentExcel} alt="CSV" style={{ width: '20px' }} />
                }
                <span style={{ textDecoration: 'underline' }}>Gerar PDF</span>
              </Button>
              <Button
                variant="text"
                sx={{
                  color: 'black',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '3px',
                  textTransform: 'none',
                }}
                onClick={handleExportAttachs}
                disabled={isLoadingDownload === 'attachs'}
              >
                {isLoadingDownload === 'attachs' ? 
                  <CircularProgress size={12}/>
                  : 
                  <img src={documentExcel} alt="CSV" style={{ width: '20px' }} />
                }
                <span style={{ textDecoration: 'underline' }}>Download anexos</span>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Container>
    </>
  );
};

export default ProjectReport;
