import { Box, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ClickAwayListener } from '@material-ui/core';
import { Link } from 'react-router-dom';

const NavbarDrawer = ({ isOpen, onClose, drawerContent }) => {
  return (
    isOpen && (
      <ClickAwayListener onClickAway={onClose}>
        <Container
          maxWidth="100%"
          sx={{
            position: 'absolute',
            zIndex: 100,
            backgroundColor: '#FCFCFC',
            boxShadow: '0px 4px 4px 0px #00000040',
            marginTop: '2px',
            paddingTop: '4px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            {drawerContent.map((item, index) => (
              <div key={index}>
                <Typography variant="h6">{item.key}</Typography>
                {item.options.map((option, index) => (
                  <Link
                    to={option.href}
                    key={index}
                    style={{ textDecoration: 'none', color: '#000' }}
                    onClick={(e) => {
                      if (window.location.pathname === option.href) {
                        e.preventDefault();
                        window.location.reload();
                      }
                      onClose();
                    }}
                  >
                    <Typography
                      my={2}
                      variant="body1"
                      sx={{ '&:hover': { color: '#EE7330' } }}
                    >
                      {option.key}
                    </Typography>
                  </Link>
                ))}
              </div>
            ))}
          </Box>
        </Container>
      </ClickAwayListener>
    )
  );
};

export default NavbarDrawer;
