import {
  Box,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
  Checkbox,
  ListItemText,
} from '@mui/material';

const HeaderSearchSelectMultipleCheckbox = ({
  label,
  onChange,
  inputValue,
  menuItems,
}) => {
  return (
    <Box sx={{ width: { xs: '100%', sm: 150, md: 200 } }}>
      <Box>
        <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
        <Select
          sx={{ width: '100%', border: 'none' }}
          id="demo-multiple-checkbox"
          size="small"
          multiple
          value={inputValue}
          displayEmpty
          disableUnderline
          variant="standard"
          renderValue={(selected) => {
            if (!selected.length) return 'Selecione...';
            return menuItems
              .filter((item) => selected.includes(item.value))
              .map((item) => item.label)
              .join(', ');
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300,
              },
            },
          }}
          onChange={onChange}
        >
          {menuItems.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              <Checkbox checked={inputValue.includes(item.value)} />
              <ListItemText
                primary={
                  item.label.length > 20 ? (
                    <Tooltip title={item.label} placement="right">
                      <span>{item.label.substring(0, 20) + '...'}</span>
                    </Tooltip>
                  ) : (
                    item.label
                  )
                }
              />
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default HeaderSearchSelectMultipleCheckbox;
