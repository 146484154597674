import { createSlice } from '@reduxjs/toolkit';
import { IState } from './interfaces/Supervisor';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  supervisors: [],
};

export const slice = createSlice({
  name: 'supervisor',
  initialState,
  reducers: {
    SUPERVISOR_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    SUPERVISOR_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    SUPERVISOR_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.supervisors = action.payload.result;
    },
    SUPERVISOR_CREATED_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
    },
    SUPERVISOR_DELETED_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.supervisors = state.supervisors.filter(
        (supervisor) =>
          supervisor.supervisorID !== action.payload.result.supervisorID
      );
    },
  },
});

export const {
  SUPERVISOR_REQUESTED,
  SUPERVISOR_FAILED,
  SUPERVISOR_SUCCESS,
  SUPERVISOR_CREATED_SUCCESS,
  SUPERVISOR_DELETED_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetSupervisor = (supervisionGroupID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Supervisor.GetSupervisor(supervisionGroupID),
    },
    onAction: {
      onStart: SUPERVISOR_REQUESTED.type,
      onError: SUPERVISOR_FAILED.type,
      onSuccess: SUPERVISOR_SUCCESS.type,
    },
  });
};

export const CreateSupervisor = (data: {
  supervisionGroupID: number;
  userID: string;
}) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.Supervisor.CreateSupervisor,
      data,
    },
    onAction: {
      onStart: SUPERVISOR_REQUESTED.type,
      onError: SUPERVISOR_FAILED.type,
      onSuccess: SUPERVISOR_CREATED_SUCCESS.type,
      showToast: true,
    },
  });
};

export const DeleteSupervisor = (supervisorID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'DELETE',
      url: Endpoints.Supervisor.DeleteSupervisor(supervisorID),
    },
    onAction: {
      onStart: SUPERVISOR_REQUESTED.type,
      onError: SUPERVISOR_FAILED.type,
      onSuccess: SUPERVISOR_DELETED_SUCCESS.type,
      showToast: true,
    },
  });
};
