import { Autocomplete, TextField, Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const HeaderSearchSelectCompany = ({
  label,
  onChange,
  error,
  defaultValue
}) => {

  const { company } = useSelector((state) => state.entities);
  const [companyInputValue, setCompanyInputValue] = useState('');
  const menuItems = company.companies
    .filter((company) => company.active == 1)
    .sort((companyA, companyB) => companyA.giveName.localeCompare(companyB.giveName))
    .map((company) => {
      return { value: company.companyID, label: company.giveName };
    })

  const companyFiltered = menuItems.filter((company) => company.value == defaultValue);

  return (
    <Box sx={{ width: { xs: '100%', sm: 150, md: 200 } }}>
      {error && (
        <Typography variant="caption" color="error">
          Campo obrigatório
        </Typography>
      )}
      <Autocomplete
        defaultValue={companyFiltered[0]}
        onChange={(event, newValue) => {
          let value = newValue === null ? '' : newValue.value;
          onChange(value);
        }}
        inputValue={companyInputValue}
        onInputChange={(event, newInputValue) => {
          setCompanyInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={menuItems}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            label={label}
            {...params}
            error={error}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            variant="standard"
          />
        )}
        isOptionEqualToValue={(option, value) => option.value === value.value}
      />
    </Box>
  );
};

export default HeaderSearchSelectCompany;
