import { Box, Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DatePickerRange from '../../../../components/DatePickerRange';
import HeaderSearchSelectMultiple from '../../../../components/HeaderSearchSelectMultiple';
import HeaderSelect from '../../../../components/HeaderSelect';
import InfoCards from '../../../../components/InfoCards';
import ResponsiveAppBar from '../../../../components/NavBar';
import CustomTable from '../../../../components/Table/CustomTable';

import { GetCostCenters } from '../../../../store/_Entities/CostCenter';
import { GetEconomyRideReport } from '../../../../store/_Entities/EconomyRideReport';
import validateDate from '../../../../utils/validateDate';
import HeaderSelectSearchable from '../../../../components/HeaderSelectSearchable';

const SavingsPerRace = () => {
  const { user, company, costCenter, economyRideReport } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();

  const userID = user.authData.sub;

  const [companiesInputValue, setCompaniesInputValue] = useState([]);
  const [costCenters, setCostCenters] = useState([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState('');

  const [data, setData] = useState([]);
  const [reports, setReports] = useState([]);

  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); //Data 7 dias atrás
  const [endDate, setEndDate] = useState(new Date());

  const [diagnosticTotal, setDiagnosticTotal] = useState(0.0);
  const [baseDiagnosticTotal, setBaseDiagnosticTotal] = useState(0.0);
  const [savingTotal, setSavingTotal] = useState(0.0);
  const [savingPercentage, setSavingPercentage] = useState(0.0);
  const [estimativeLossTotal, setEstimativeLossTotal] = useState(0.0);
  const [reestimateLossPercentage, setReestimateLossPercentage] = useState(0.0);
  const [differentEstimativeLossTotal, setDifferentEstimativeLossTotal] =
    useState(0.0);
  const [differentEstimativePercentage, setDifferentEstimativePercentage] =
    useState(0.0);
  const [totalEconomyWexp, setTotalEconomyWexp] = useState(0.0);
  const [totalEconomyWexpPercentage, setTotalEconomyWexpPercentage] =
    useState(0.0);

  const fetchReports = () => {
    const startDateFormatted = startDate.toLocaleDateString('pt-BR');
    const endDateFormatted = endDate.toLocaleDateString('pt-BR');

    const request = {
      userID,
      companyID: 0,
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      costCenterID: selectedCostCenter || '0',
      companies: companiesInputValue.length
        ? companiesInputValue.map((u) => u.companyID)
        : [0],
    };

    dispatch(GetEconomyRideReport(request));
  };

  useEffect(() => {
    if (companiesInputValue.length > 0) {
      dispatch(GetCostCenters(companiesInputValue[0].companyID));
    }
  }, [companiesInputValue]);

  useEffect(() => {
    if (
      companiesInputValue.length > 0 &&
      costCenter.costCenters &&
      costCenter.success
    ) {
      setCostCenters(costCenter.costCenters);
    }
  }, [companiesInputValue]);

  useEffect(() => {
    if (
      companiesInputValue.length > 0 &&
      validateDate(startDate) &&
      validateDate(endDate)
    ) {
      fetchReports();
    }
  }, [companiesInputValue, selectedCostCenter, startDate, endDate]);

  useEffect(() => {
    if (
      economyRideReport?.economyRideReports &&
      companiesInputValue.length > 0
    ) {
      setData(economyRideReport.economyRideReports);
      if (economyRideReport.economyRideReports?.reportResults) {
        setReports(economyRideReport.economyRideReports.reportResults);
      }
    }
  }, [companiesInputValue, economyRideReport.economyRideReports]);

  useEffect(() => {
    if (economyRideReport?.economyRideReports) {
      setDiagnosticTotal(economyRideReport.economyRideReports.diagnosticTotal);
      setBaseDiagnosticTotal(
        economyRideReport.economyRideReports.baseDiagnosticTotal
      );
      setSavingTotal(economyRideReport.economyRideReports.savingTotal);
      setSavingPercentage(
        economyRideReport.economyRideReports.savingPercentage
      );
      setEstimativeLossTotal(
        economyRideReport.economyRideReports.estimativeLossTotal
      );
      setReestimateLossPercentage(
        economyRideReport.economyRideReports.reestimateLossPercentage
      );
      setDifferentEstimativeLossTotal(
        economyRideReport.economyRideReports.differentEstimativeLossTotal
      );
      setDifferentEstimativePercentage(
        economyRideReport.economyRideReports.differentEstimativePercentage
      );

      setTotalEconomyWexp(
        economyRideReport.economyRideReports.totalEconomyWexp
      );
      setTotalEconomyWexpPercentage(
        economyRideReport.economyRideReports.totalEconomyWexpPercentage
      );
    }
  }, [economyRideReport.economyRideReports]);

  const columns = [
    {
      field: 'rideID',
      headerName: 'ID da Corrida',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'company',
      headerName: 'Empresa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'userID',
      headerName: 'ID do Usuário',
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Nome do Usuário',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'registration',
      headerName: 'Matrícula',
      headerAlign: 'center',
      align: 'center',
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'startDate',
      headerName: 'Data de Início',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      // cellRenderer: (params) => {
      //   return params.row.startDate.toLocaleDateString('pt-BR');
      // },
    },
    {
      field: 'endDate',
      headerName: 'Data de Fim',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'provider',
      headerName: 'Fornecedor',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'product',
      headerName: 'Produto',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },

    {
      field: 'priceDiagnostic',
      headerName: 'Valor Corrida Diagnóstico (REF)',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Valor Corrida Cobrada (CC)',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'saving',
      headerName: 'Valor Economia vs REF',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'savingPercent',
      headerName: 'Porcentagem Economia vs REF',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'reEstimateLoss',
      headerName:
        'Economia Desperdiçada por Uso de Produto Não Recomendado (Recotação)',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'differentEstimativeLoss',
      headerName:
        'Economia Desperdiçada por Uso de Produto Não Recomendado (Seleção)',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'categoryPriceDifference',
      headerName: 'Porcentagem de Afastamento',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    // { field: 'diagnosisValue', headerName: 'Valor do Diagnóstico', headerAlign: 'center', align: 'center', minWidth: 150, flex: 1 }
  ];

  const exportHeaders = {
    rideID: 'ID da Corrida',
    company: 'Empresa',
    userID: 'ID do Usuário',
    name: 'Nome do Usuário',
    registration: 'Matrícula',
    email: 'Email',
    phone: 'Telephone',
    startDate: 'Data de Início',
    endDate: 'Data de Fim',
    costCenter: 'Centro de Custo',
    provider: 'Fornecedor',
    product: 'Produto',

    priceDiagnostic: 'Valor Corrida Diagnóstico (REF)',
    price: 'Valor Corrida Cobrada (CC)',
    saving: 'Valor Economia vs REF',
    savingPercent: 'Porcentagem Economia vs REF',
    reEstimateLoss:
      'Economia Desperdiçada por Uso de Produto Não Recomendado (Recotação)',
    differentEstimativeLoss:
      'Economia Desperdiçada por Uso de Produto Não Recomendado (Seleção)',
    categoryPriceDifference: 'Porcentagem de Afastamento',
    // diagnosisValue: 'Valor do Diagnóstico'
  };

  const cardsData = [
    {
      title: 'Total Corrida Diagnóstico (REF)',
      value: `R$ ${baseDiagnosticTotal || 0}`,
    },
    {
      title: 'Total Corrida Cobrada (CC)',
      value: `R$ ${diagnosticTotal || 0.0} `,
    },
    {
      title: 'Economia vs REF',
      value: `R$ ${savingTotal || 0}`,
      percentual: `${savingPercentage || 0}%`,
    },
    {
      title:
        'Economia Desperdiçada por Uso de Produto Não Recomendado (Recotação)',
      value: `R$ ${estimativeLossTotal || 0}`,
      percentual: `${reestimateLossPercentage || 0}%`,
    },
    {
      title:
        'Economia Desperdiçada por Uso de Produto Não Recomendado (Seleção)',
      value: `${differentEstimativeLossTotal || 0}%`,
      percentual: `${differentEstimativePercentage || 0}%`,
    },
    {
      title: 'Total de Economias WEXP % Economias WEXP',
      value: `${totalEconomyWexp || 0}%`,
      percentual: `${totalEconomyWexpPercentage || 0}%`,
    },
  ];

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black',
            }}
          >
            <h2>Economia por Corrida</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end',
              }}
            >
              <Box sx={{ display: 'flex', gap: 5 }}>
                <HeaderSearchSelectMultiple
                  label="Empresas"
                  onChange={(e) => {
                    setCompaniesInputValue(e.target.value);
                  }}
                  inputValue={companiesInputValue}
                  menuItems={company?.companies
                    ?.filter((company) => company.active == 1)
                    .sort((companyA, companyB) =>
                      companyA.giveName.localeCompare(companyB.giveName)
                    )
                    .map((company) => {
                      return { label: company.giveName, value: company };
                    })}
                  menuItemsSelectedChips={companiesInputValue?.map(
                    (company) => {
                      return { label: company.giveName, value: company };
                    }
                  )}
                />
                <DatePickerRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={{ startDate: setStartDate, endDate: setEndDate }}
                />
                <HeaderSelectSearchable
                  label="Centro de Custo"
                  onChange={setSelectedCostCenter}
                  menuItems={[
                    {
                      label: 'TODOS',
                      value: 0,
                    },
                    ...costCenters.map((costCenter) => {
                      return {
                        label:
                          costCenter.number + ' - ' + costCenter.description,
                        value: costCenter.costCenterID,
                      };
                    }),
                  ]}
                  defaultValue={undefined}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box
          mb={6}
          sx={{ display: `flex`, justifyContent: 'space-between' }}
        ></Box>
        <InfoCards cardsData={cardsData} />
        <CustomTable
          columns={columns}
          data={reports}
          getRowId={(row) => row.rideID}
          exportHeaders={exportHeaders}
        />
      </Container>
    </>
  );
};

export default SavingsPerRace;
