import { Box, Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerRange from '../../../../components/DatePickerRange';
import HeaderSearchSelectMultiple from '../../../../components/HeaderSearchSelectMultiple';
import HeaderSelect from '../../../../components/HeaderSelect';
import ResponsiveAppBar from '../../../../components/NavBar';
import CustomTable from '../../../../components/Table/CustomTable';
import { GetCostCenters } from '../../../../store/_Entities/CostCenter';
import { GetNonConformityReport } from '../../../../store/_Entities/NonConformityReport';
import HeaderSelectSearchable from '../../../../components/HeaderSelectSearchable';

const ManagementAlerts = () => {
  const { user, company, costCenter, nonConformityReport } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();

  const userID = user.authData.sub;

  const [companiesInputValue, setCompaniesInputValue] = useState([]);

  const [selectedCostCenter, setSelectedCostCenter] = useState('');
  const [costCenters, setCostCenters] = useState([]);
  const [selectedWarningType, setSelectedWarningType] = useState(0);

  const [data, setData] = useState([]);

  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); //Data 7 dias atrás
  const [endDate, setEndDate] = useState(new Date());

  const fetchReports = () => {
    const startDateFormatted = startDate.toLocaleDateString('pt-BR');
    const endDateFormatted = endDate.toLocaleDateString('pt-BR');

    const request = {
      userID,
      companyID: 0,
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      costCenterID: selectedCostCenter || '0',
      companies: companiesInputValue.length
        ? companiesInputValue.map((u) => u.companyID)
        : [0],
      warningTypeID: selectedWarningType || 0,
    };

    dispatch(GetNonConformityReport(request));
  };

  useEffect(() => {
    if (companiesInputValue.length > 0) {
      dispatch(GetCostCenters(companiesInputValue[0].companyID));
    }
  }, [companiesInputValue]);

  useEffect(() => {
    if (
      companiesInputValue.length > 0 &&
      costCenter.costCenters &&
      costCenter.success
    ) {
      setCostCenters(costCenter.costCenters);
    }
  }, [companiesInputValue]);

  useEffect(() => {
    if (companiesInputValue.length > 0 && startDate && endDate) {
      fetchReports();
    }
  }, [
    companiesInputValue,
    selectedCostCenter,
    startDate,
    endDate,
    selectedWarningType,
  ]);

  useEffect(() => {
    if (
      nonConformityReport?.nonConformityReports &&
      companiesInputValue.length > 0
    ) {
      setData(nonConformityReport.nonConformityReports);
    }
  }, [companiesInputValue, nonConformityReport.nonConformityReports]);

  const columns = [
    {
      field: 'expenseID',
      headerName: 'ID da Despesa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'expenseType',
      headerName: 'Tipo de Despesa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'source',
      headerName: 'Fonte',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'paymentType',
      headerName: 'Tipo de Pagamento',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'provider',
      headerName: 'Fornecedor',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'product',
      headerName: 'Produto',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'report',
      headerName: 'Relatório',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'company',
      headerName: 'Empresa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'userID',
      headerName: 'ID do Usuário',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'user',
      headerName: 'Nome',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'addressStart',
      headerName: 'Endereço de Partida',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'addressEnd',
      headerName: 'Endereço de Chegada',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'totalStops',
      headerName: 'Total de Paradas',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'distance',
      headerName: 'Distância (KM)',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Valor',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'created',
      headerName: 'Data',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'warningdescription',
      valueGetter: (params) => params.row.warning.description,
      headerName: 'Não Conformidade',
      headerAlign: 'center',
      align: 'center',
      minWidth: 300,
      flex: 1,
    },
    {
      field: 'value',
      valueGetter: (params) => params.row.warning.value,
      headerName: 'Valor da Não Conformidade',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
  ];

  const exportHeaders = {
    expenseID: 'ID da Despesa',
    expenseType: 'Tipo de Despesa',
    source: 'Fonte',
    paymentType: 'Tipo de Pagamento',
    provider: 'Fornecedor',
    product: 'Produto',
    report: 'Relatório',
    company: 'Empresa',
    userID: 'ID do Usuário',
    user: 'Nome',
    costCenter: 'Centro de Custo',
    description: 'Descrição',
    addressStart: 'Endereço de Partida',
    addressEnd: 'Endereço de Chegada',
    totalStops: 'Total de Paradas',
    distance: 'Distância (KM)',
    price: 'Valor',
    created: 'Data',
    // warning.description: 'Não Conformidade',
    // warning.value: 'Valor da Não Conformidade'
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black',
            }}
          >
            <h2>Relatório de Alertas Gerenciais</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end',
              }}
            >
              <Box sx={{ display: 'flex', gap: 5 }}>
                <HeaderSearchSelectMultiple
                  label="Empresas"
                  onChange={(e) => {
                    setCompaniesInputValue(e.target.value);
                  }}
                  inputValue={companiesInputValue}
                  menuItems={company?.companies
                    ?.filter((company) => company.active == 1)
                    .sort((companyA, companyB) =>
                      companyA.giveName.localeCompare(companyB.giveName)
                    )
                    .map((company) => {
                      return { label: company.giveName, value: company };
                    })}
                  menuItemsSelectedChips={companiesInputValue?.map(
                    (company) => {
                      return { label: company.giveName, value: company };
                    }
                  )}
                />
                <DatePickerRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={{ startDate: setStartDate, endDate: setEndDate }}
                />
                <HeaderSelectSearchable
                  label="Centro de Custo"
                  onChange={setSelectedCostCenter}
                  menuItems={[
                    {
                      label: 'TODOS',
                      value: 0,
                    },
                    ...costCenters.map((costCenter) => {
                      return {
                        label:
                          costCenter.number + ' - ' + costCenter.description,
                        value: costCenter.costCenterID,
                      };
                    }),
                  ]}
                  defaultValue={undefined}
                />
                <HeaderSelect
                  label="Alertas Gerenciais:"
                  onChange={(event) => {
                    setSelectedWarningType(event.target.value);
                  }}
                  menuItems={[
                    {
                      label: 'ECONOMIA PERDIDA POR FALTA DE REFERÊNCIA',
                      value: 51,
                    },
                    {
                      label: 'ECONOMIA PERDIDA COM CORRIDAS NO FORNECEDOR',
                      value: 5,
                    },
                    {
                      label: 'ECONOMIA PERDIDA POR LIMITAÇÃO DE PRODUTO',
                      value: 4,
                    },
                    { label: 'PENALIDADES E CRÉDITOS', value: 13 },
                    { label: 'GORJETAS', value: 4 },
                  ]}
                  defaultValue={undefined}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.expenseID}
          exportHeaders={exportHeaders}
          loading={nonConformityReport.loading}
        />
      </Container>
    </>
  );
};

export default ManagementAlerts;
