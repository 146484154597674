import { useEffect, useState } from 'react';
import ResponsiveAppBar from '../../../components/NavBar';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CustomTable from '../../../components/Table/CustomTable';
import Header from '../../../components/Header';
import HeaderSelect from '../../../components/HeaderSelect';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckAttachments,
  CreateReport,
  DeleteExpense,
  DeleteReport,
  DownloadAttachments,
  EditExpensesReport,
  EXPENSES_CLEAR_DOWNLOAD_FILE,
  GetExpensesReportPagination,
  GetExpensesReportEdit,
  SendApproval,
} from '../../../store/_Entities/ExpensesReport';
import { ModalWrapper } from '../../../components/Modal';
import { useForm, Controller } from 'react-hook-form';
import FormInput from '../../../components/FormInput';
import ModalConfirm from '../../../components/ModalConfirm';
import CustomDataGrid from '../../../components/grids/components/CustomDataGrid';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SendIcon from '@mui/icons-material/Send';
import ApprovalIcon from '@mui/icons-material/Done';
import DownloadIcon from '@mui/icons-material/Download';
import { GetExpensesReportApprovalFlow } from '../../../store/_Entities/ExpensesReport';
import ApprovalStepper from '../../../components/ApprovalStepper';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import ModalErrorSuccess from '../../../components/ModalErrorSuccess';
import Checked from '../../../assets/images/checked.png';
import { numberToCurrency } from '../../../utils/format';

const Reports = () => {
  const { expensesReport } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const [reportStatus, setReportStatus] = useState(-1);
  const [reportNature, setReportNature] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState({
    value: false,
    type: null,
  });
  const [isLoadingDownload, setIsLoadingDownload] = useState({
    show: false,
    type: null,
  });

  const [showModal, setShowModal] = useState({ show: false, type: null });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSendToApproveModal, setShowSendToApproveModal] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState({
    show: false,
    submit: false,
  });
  const [showErrorModal, setShowErrorModal] = useState({
    submit: false,
    show: false,
    type: null,
  });

  const [data, setData] = useState([]);
  const [editData, setEditData] = useState([]);  
  const [totalRows, setTotalRows] = useState(0);  
  const [pagNum, setPagNum] = useState(0);
  const [pagSize, setPagSize] = useState(25);

  const handleDelete = () => {
    dispatch(DeleteReport(selectedRow.reportID));
    setShowDeleteModal(false);
    setHasSubmitted({ value: true, type: 'delete' });
  };

  const handleUnLink = (row) => {
    dispatch(DeleteExpense(row.expenseID));
    setHasSubmitted({ value: true, type: 'unlink' });
  };

  const onSubmit = (data) => {
    if (showModal.type === 'add') {
      dispatch(CreateReport(data.description));
      setHasSubmitted({ value: true, type: 'add' });
    }
    if (showModal.type === 'edit') {
      const reqData = {
        reportID: selectedRow.reportID,
        description: data.description,
      };
      dispatch(EditExpensesReport(reqData));
    }
    setShowModal({ show: false, type: null });
  };

  const handleDownloadPdf = async () => {
    setIsLoadingDownload({ show: true, type: 'pdf' });
    const data = {
      tasks: {
        'task-1': {
          operation: 'capture-website',
          url: `https://wexp-exponential.azurewebsites.net/Invoice/ExpenseReportsLayout?ReportID=${selectedRow.reportID}`,
          output_format: 'pdf',
          engine: 'chrome',
          zoom: 1,
          page_width: 50,
          page_height: 50,
          print_media_type: true,
          margin_top: 10,
          margin_bottom: 10,
          margin_left: 10,
          margin_right: 10,
          print_background: true,
          display_header_footer: false,
          wait_until: 'load',
          wait_time: 0,
          filename: 'Relátorio de Despesas.pdf',
        },
        'export-1': {
          operation: 'export/url',
          input: ['task-1'],
          inline: false,
          archive_multiple_files: false,
        },
      },
    };

    try {
      const createJobResponse = await axios.post(
        'https://api.cloudconvert.com/v2/jobs',
        data,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CLOUD_CONVERT_TOKEN} `,
            'Content-Type': 'application/json',
          },
        }
      );

      const id = createJobResponse.data.data.id;

      const waitResponse = await axios.get(
        `https://api.cloudconvert.com/v2/jobs/${id}/wait`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CLOUD_CONVERT_TOKEN} `,
          },
        }
      );

      const fileUrl = waitResponse.data.data.tasks[0].result.files[0].url;
      window.open(fileUrl, '_blank');
    } catch (error) {}
    setIsLoadingDownload({ show: false, type: null });
  };

  const handleDownloadAttachs = () => {
    dispatch(CheckAttachments(selectedRow.reportID));
    setShowErrorModal({ submit: true, show: false });
    setIsLoadingDownload({ show: true, type: 'attachs' });
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      description: '',
    },
  });

  const reportChoiceUpdate = () => {
    if (reportStatus == -1) {
      dispatch(GetExpensesReportPagination(0, reportNature, (pagNum + 1), pagSize));
    } else {
      dispatch(GetExpensesReportPagination(reportStatus, reportNature, (pagNum + 1), pagSize));
    }
  };

  useEffect(() => {
    reportChoiceUpdate();
  }, [reportStatus, reportNature, pagNum, pagSize]);

  useEffect(() => {
    if (expensesReport.reportsPagination) {
      setData(expensesReport.reportsPagination.data);
      setTotalRows(expensesReport.reportsPagination.totalCount)
    }
  }, [expensesReport.reportsPagination, reportStatus]);

  useEffect(() => {
    if (
      showModal.show &&
      (showModal.type === 'edit' || showModal.type === 'sendApproval') &&
      selectedRow
    ) {
      dispatch(GetExpensesReportEdit(selectedRow.reportID));
      setValue('description', selectedRow.description);
    }
    if (showModal.show && showModal.type === 'add') {
      setValue('description', '');
    }
  }, [showModal]);

  useEffect(() => {
    if (
      (showModal || showSendToApproveModal) &&
      (showModal.type === 'edit' || showModal.type === 'sendApproval') &&
      expensesReport.reportExpenses
    ) {
      setEditData(expensesReport.reportExpenses);
    }
  }, [expensesReport.reportExpenses]);

  useEffect(() => {
    if (showApprovalModal) {
      dispatch(GetExpensesReportApprovalFlow(selectedRow.reportID));
    }
  }, [showApprovalModal]);

  useEffect(() => {
    if (showErrorModal.submit && expensesReport.error) {
      setShowErrorModal((prev) => ({ ...prev, show: true, type: 'error' }));
    }
  }, [showErrorModal, expensesReport]);

  useEffect(() => {
    if (
      hasSubmitted.value &&
      hasSubmitted.type === 'unlink' &&
      !expensesReport.loading
    ) {
      setHasSubmitted(false);
      dispatch(GetExpensesReportEdit(selectedRow.reportID));
    }
  }, [hasSubmitted, expensesReport.loading]);

  useEffect(() => {
    if (
      hasSubmitted.value &&
      (hasSubmitted.type === 'delete' ||
        hasSubmitted.type === 'add' ||
        hasSubmitted.type === 'sendApproval') &&
      !expensesReport.loading
    ) {
      setHasSubmitted(false);
      reportChoiceUpdate();
    }
  }, [hasSubmitted, expensesReport.loading]);

  useEffect(() => {
    if (
      expensesReport.checkAttachments &&
      showErrorModal.submit &&
      expensesReport.success &&
      !expensesReport.loading
    ) {
      dispatch(DownloadAttachments(selectedRow.reportID));
      setShowErrorModal((prev) => ({ ...prev, submit: false }));
      setShowDownloadModal((prev) => ({ ...prev, submit: true }));
    }
    if (
      showErrorModal.submit &&
      !expensesReport.checkAttachments &&
      expensesReport.success &&
      !expensesReport.loading &&
      showErrorModal.type !== 'error'
    ) {
      setIsLoadingDownload({ show: false, type: null });
      setShowErrorModal(() => ({ show: true, submit: false, type: 'check' }));
    }
  }, [expensesReport, selectedRow, showErrorModal]);

  useEffect(() => {
    if (
      expensesReport.error &&
      !expensesReport.loading &&
      expensesReport.errorMessage
    ) {
      setShowErrorModal((prev) => ({
        show: true,
        submit: false,
        type: 'error',
      }));
    } else if (
      expensesReport.success &&
      !expensesReport.loading &&
      hasSubmitted.type == 'sendApproval' &&
      hasSubmitted.value == true
    ) {
      setShowSuccessModal(true);
    }
  });
  [expensesReport.error, expensesReport.loading, expensesReport.errorMessage];
  useEffect(() => {}, [showErrorModal.type]);

  useEffect(() => {
    if (expensesReport.downloadFile && showDownloadModal.submit) {
      const file = expensesReport.downloadFile;
      const url = window.URL.createObjectURL(
        new Blob([file], { type: 'application/zip' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Anexos.zip');
      document.body.appendChild(link);
      link.click();
      setIsLoadingDownload({ show: false, type: null });
      dispatch(EXPENSES_CLEAR_DOWNLOAD_FILE());
    }
  }, [expensesReport.downloadFile, showDownloadModal.submit]);

  const columns = [
    {
      field: 'reportID',
      headerName: 'Código',
      headerAlign: 'center',
      align: 'center',
      minWidth: 75,
      flex: 1,
    },
    {
      field: 'createdString',
      headerName: 'Data de Criação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 170,
      flex: 1,
    },
    {
      field: 'reportStatus',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params) => {
        if (params.value === 'Recusado') {
          return 'color-recusado';
        }
      },
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'totalAmount',
      headerName: 'Valor Total do Relatório',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      valueFormatter: (params) => numberToCurrency(params.value),
    },
    {
      field: 'advancedAmount',
      headerName: 'Valor do Adiantamento',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      valueFormatter: (params) => numberToCurrency(params.value),
    },
    {
      field: 'sendToApproval',
      headerName: 'Enviar para Aprovação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (
          params.row.reportStatusID !== 2 &&
          params.row.reportStatusID !== 3 &&
          params.row.reportStatusID !== 5
        ) {
          return (
            <IconButton
              onClick={() => {
                setShowModal({ show: true, type: 'sendApproval' });
                setSelectedRow(params.row);
                setShowSendToApproveModal(true);
                ////////
              }}
            >
              <SendIcon />
            </IconButton>
          );
        }
        return null;
      },
    },
    {
      field: 'followApproval',
      headerName: 'Acompanhar Aprovação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (
          params.row.reportStatusID !== 1 &&
          params.row.reportStatusID !== 9
        ) {
          return (
            <IconButton
              onClick={() => {
                setSelectedRow(params.row);
                setShowApprovalModal(true);
              }}
            >
              <ApprovalIcon />
            </IconButton>
          );
        }
        return null;
      },
    },
    {
      field: 'download',
      headerName: 'Downloads',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (
          params.row.reportStatusID !== 1 &&
          params.row.reportStatusID !== 9
        ) {
          return (
            <IconButton
              onClick={() => {
                setSelectedRow(params.row);
                setShowDownloadModal((prev) => ({ ...prev, show: true }));
              }}
            >
              <DownloadIcon />
            </IconButton>
          );
        }
        return null;
      },
    },
    {
      field: 'edit',
      headerName: 'Editar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (
          params.row.reportStatusID === 1 ||
          params.row.reportStatusID === 4 ||
          params.row.reportStatusID === 9
        ) {
          return (
            <IconButton
              onClick={() => {
                setSelectedRow(params.row);
                setShowModal({ show: true, type: 'edit' });
              }}
            >
              <EditIcon />
            </IconButton>
          );
        }
        return null;
      },
    },
    {
      field: 'delete',
      headerName: 'Deletar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (
          params.row.reportStatusID === 1 ||
          params.row.reportStatusID === 4 ||
          params.row.reportStatusID === 9
        ) {
          return (
            <IconButton
              onClick={() => {
                setSelectedRow(params.row);
                setShowDeleteModal(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          );
        }
        return null;
      },
    },
    {
      field: 'justificativa',
      headerName: 'Justificativa da Reprovação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
      flex: 1,
      renderCell: (params) => {
        if (params.row.reportStatusID === 4 && params.row.justificativa) {
          return (
            <Tooltip title={params.row.justificativa} placement="top">
              <InfoIcon />
            </Tooltip>
          );
        }
        return <div></div>;
      },
    },
  ];

  const exportHeaders = {
    reportID: 'Código',
    createdString: 'Data de Criação',
    description: 'Descrição',
    reportStatus: 'Status',
    totalAmount: 'Valor Total do Relatório',
    advancedAmount: 'Valor do Adiantamento',
  };

  const onChangePagNum = (pagNum) => {
    setPagNum(pagNum)
  }
  
  const onChgangePagSize = (pagSize) => {
    setPagSize(pagSize)
  }

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Header title="Relatório de Despesas">
          <Grid container spacing={4} alignItems={'center'}>
            <Grid item xs={12} sm={4}>
              <HeaderSelect
                label="Status Relatório"
                onChange={(event) => {
                  setReportStatus(event.target.value);
                }}
                menuItems={[
                  { label: '(default)', value: -1 },
                  { label: 'Pendente', value: 1 },
                  { label: 'Aguardando Aprovação', value: 2 },
                  { label: 'Aprovado', value: 3 },
                  { label: 'Recusado', value: 4 },
                  { label: 'Pagamento Confirmado', value: 5 },
                  { label: 'Fechamento', value: 9 },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <HeaderSelect
                label="Natureza relatório"
                onChange={(event) => {
                  setReportNature(event.target.value);
                }}
                menuItems={[
                  { label: 'Todos', value: 0 },
                  { label: 'Reembolso de Depsesas', value: 1 },
                  { label: 'Prestação de Contas', value: 2 },
                ]}
                defaultValue={undefined}
              />
            </Grid>
            <Grid item xs={12} sm={4} textAlign={'end'}>
              <Button
                variant="contained"
                onClick={() => setShowModal({ show: true, type: 'add' })}
              >
                Adicionar
              </Button>
            </Grid>
          </Grid>
        </Header>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.reportID}
          exportHeaders={exportHeaders}
          loading={
            expensesReport.loading &&
            !showApprovalModal &&
            !showModal.show &&
            !showDeleteModal &&
            !showDownloadModal.show
          }
          pagNum={pagNum}
          pagSize={pagSize}
          totalCount={totalRows}
          onChangePagNum={onChangePagNum}
          onChgangePagSize={onChgangePagSize}
        />
        <ModalWrapper
          title="Cadastro de Relatório"
          showModal={showModal.show && showModal.type === 'edit'}
          closeModalAction={() => setShowModal({ show: false, type: null })}
          maxWidth={showModal.type === 'edit' ? 'md' : 'sm'}
        >
          <Grid
            container
            spacing={2}
            display={'flex'}
            flexDirection={'column'}
            gap={2}
          >
            {showModal.type === 'edit' && (
              <Grid item xs={12}>
                <CustomDataGrid
                  miniature={true}
                  width={{
                    xs: '300px',
                    md: '800px',
                  }}
                  noRowsText
                  rows={editData?.expenses || []}
                  columns={[
                    {
                      field: 'expenseType',
                      headerName: 'Tipo de Despesa',
                      headerAlign: 'center',
                      align: 'center',
                      minWidth: 150,
                      flex: 1,
                    },
                    {
                      field: 'price',
                      headerName: 'Valor',
                      headerAlign: 'center',
                      align: 'center',
                      minWidth: 100,
                      flex: 1,
                    },
                    {
                      field: 'currency',
                      headerName: 'Moeda',
                      headerAlign: 'center',
                      align: 'center',
                      minWidth: 60,
                      flex: 1,
                    },
                    {
                      field: 'date',
                      headerName: 'Data',
                      headerAlign: 'center',
                      align: 'center',
                      minWidth: 150,
                      flex: 1,
                      valueFormatter: (params) => {
                        return new Date(params.value).toLocaleDateString(
                          'pt-BR'
                        );
                      },
                    },
                    {
                      field: 'description',
                      headerName: 'Descrição',
                      headerAlign: 'center',
                      align: 'center',
                      minWidth: 150,
                      flex: 1,
                    },
                    {
                      field: 'edit',
                      headerName: 'Desvincular',
                      headerAlign: 'center',
                      align: 'center',
                      minWidth: 100,
                      flex: 1,
                      renderCell: (params) => (
                        <IconButton
                          onClick={() => {
                            handleUnLink(params.row);
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      ),
                    },
                  ]}
                  props={{
                    getRowId: (row) => row.expenseID,
                    disableSelectionOnClick: true,
                    loading: expensesReport.loading,
                  }}
                />
              </Grid>
            )}
          </Grid>
        </ModalWrapper>
        <ModalWrapper
          title="Cadastro de Relatório"
          showModal={showModal.show && showModal.type === 'add'}
          closeModalAction={() => setShowModal({ show: false, type: null })}
          maxWidth={'sm'}
        >
          <Grid
            container
            spacing={2}
            display={'flex'}
            flexDirection={'column'}
            gap={2}
          >
            {showModal.type === 'add' && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid item xs={12}>
                  <Controller
                    name="description"
                    control={control}
                    rules={{ required: 'Campo obrigatório' }}
                    render={({ field }) => (
                      <FormInput
                        field={field}
                        label={'Descrição'}
                        errors={errors.description}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={'flex'}
                  justifyContent={'flex-end'}
                  mt={2}
                  gap={2}
                >
                  <Button variant="contained" type="submit">
                    Salvar
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => setShowModal({ show: false, type: null })}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </form>
            )}
          </Grid>
        </ModalWrapper>
        <ModalWrapper
          title={'Visualizar Fluxo de Aprovação'}
          showModal={showApprovalModal}
          closeModalAction={() => setShowApprovalModal(false)}
          maxWidth={expensesReport?.approvalFlow.length > 3 ? 'md' : 'sm'}
        >
          <Box>
            <Typography variant="h6">Status da aprovação:</Typography>
            <Box display={'flex'} justifyContent={'center'}>
              {expensesReport.loading ? (
                <CircularProgress />
              ) : (
                <ApprovalStepper data={expensesReport?.approvalFlow} />
              )}
            </Box>
          </Box>
        </ModalWrapper>
        <ModalWrapper
          title={'Download do Relatório'}
          showModal={showDownloadModal.show}
          closeModalAction={() =>
            setShowDownloadModal({ show: false, submit: false })
          }
        >
          <Box display={'flex'} gap={4}>
            <Button variant="contained" size="lg" onClick={handleDownloadPdf}>
              {isLoadingDownload.show && isLoadingDownload.type === 'pdf' ? (
                <CircularProgress style={{ color: 'white' }} />
              ) : (
                'Download PDF'
              )}
            </Button>
            <Button
              variant="contained"
              size="lg"
              disabled={!(selectedRow?.reportStatusID === 5)}
              onClick={handleDownloadAttachs}
            >
              {isLoadingDownload.show &&
              isLoadingDownload.type === 'attachs' ? (
                <CircularProgress style={{ color: 'white' }} />
              ) : (
                'Download Anexos'
              )}
            </Button>
          </Box>
        </ModalWrapper>
        <ModalConfirm
          title={'Deseja Excluir o Relatório?'}
          show={showDeleteModal}
          submit={handleDelete}
          handleClose={() => setShowDeleteModal(false)}
        >
          <Box>
            <Typography variant="subtitle1">
              O relatório será excluido permanentemente.
            </Typography>
          </Box>
        </ModalConfirm>
        <ModalConfirm
          title={'Deseja Enviar o Relatório para Aprovação?'}
          show={showSendToApproveModal}
          submit={() => {
            try {
              dispatch(SendApproval(selectedRow.reportID));
              console.log('err1:', expensesReport.errorMessage);
              setShowSendToApproveModal(false);
              setHasSubmitted({ value: true, type: 'sendApproval' });
            } catch (err) {
              console.log('err2:', expensesReport.errorMessage);
              setShowErrorModal((prev) => ({ ...prev, submit: true }));
            }
          }}
          maxWidth={'md'}
          handleClose={() => setShowSendToApproveModal(false)}
        >
          <Grid
            container
            spacing={2}
            display={'flex'}
            flexDirection={'column'}
            gap={2}
          >
            <Grid item xs={12}>
              <CustomDataGrid
                miniature={true}
                width={{
                  xs: '300px',
                  md: '800px',
                }}
                noRowsText
                rows={editData?.expenses || []}
                columns={[
                  {
                    field: 'expenseType',
                    headerName: 'Tipo de Despesa',
                    headerAlign: 'center',
                    align: 'center',
                    minWidth: 150,
                    flex: 1,
                  },
                  {
                    field: 'price',
                    headerName: 'Valor',
                    headerAlign: 'center',
                    align: 'center',
                    minWidth: 100,
                    flex: 1,
                  },
                  {
                    field: 'currency',
                    headerName: 'Moeda',
                    headerAlign: 'center',
                    align: 'center',
                    minWidth: 60,
                    flex: 1,
                  },
                  {
                    field: 'date',
                    headerName: 'Data',
                    headerAlign: 'center',
                    align: 'center',
                    minWidth: 150,
                    flex: 1,
                    valueFormatter: (params) => {
                      return new Date(params.value).toLocaleDateString('pt-BR');
                    },
                  },
                  {
                    field: 'description',
                    headerName: 'Descrição',
                    headerAlign: 'center',
                    align: 'center',
                    minWidth: 150,
                    flex: 1,
                  },
                ]}
                props={{
                  getRowId: (row) => row.expenseID,
                  loading: expensesReport.loading,
                }}
              />
            </Grid>
          </Grid>
        </ModalConfirm>
        <ModalErrorSuccess
          handleClose={() => {
            setShowErrorModal({ submit: false, show: false, type: null });
            setShowSuccessModal(false);
          }}
          show={showSuccessModal || showErrorModal.show}
          title={
            showSuccessModal
              ? 'Enviado para Aprovação com Sucesso'
              : showErrorModal.type === 'error'
              ? 'Erro!'
              : 'Alerta!'
          }
          error={showSuccessModal ? false : true}
        >
          <Box>
            <Typography variant="subtitle1">
              {showErrorModal.type === 'error' && !showSuccessModal
                ? typeof expensesReport.errorMessage === 'string'
                  ? expensesReport.errorMessage
                  : expensesReport.errorMessage.result.errors[0]
                : 'Esse relatório não contém anexos'}
            </Typography>
          </Box>
        </ModalErrorSuccess>
      </Container>
    </>
  );
};

export default Reports;
