import { createSlice } from '@reduxjs/toolkit';
import {
  IState,
  IUserConfigurationRequest,
} from './interfaces/UserConfiguration';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  userActiveConfigurations: [],
  userConfigurations: [],
};

export const slice = createSlice({
  name: 'userConfiguration',
  initialState,
  reducers: {
    USER_CONFIGURATION_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    USER_CONFIGURATION_ADD_REQUESTED: (state, action) => {
      state.error = false;
      state.success = false;
    },
    USER_CONFIGURATION_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    USER_CONFIGURATION_ACTIVE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.userActiveConfigurations = action.payload.result;
    },
    ADD_USER_CONFIGURATION_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
    },
    USER_CONFIGURATION_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.userConfigurations = action.payload.result;
    },
  },
});

export const {
  USER_CONFIGURATION_REQUESTED,
  USER_CONFIGURATION_ADD_REQUESTED,
  USER_CONFIGURATION_FAILED,
  USER_CONFIGURATION_ACTIVE_SUCCESS,
  ADD_USER_CONFIGURATION_SUCCESS,
  USER_CONFIGURATION_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetActiveUserConfigurations = (UserID: string) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.UserConfiguration.GetUserConfigurationActive(UserID),
    },
    onAction: {
      onStart: USER_CONFIGURATION_REQUESTED.type,
      onError: USER_CONFIGURATION_FAILED.type,
      onSuccess: USER_CONFIGURATION_ACTIVE_SUCCESS.type,
    },
  });
};

export const AddActiveUserConfiguration = (
  UserID: string,
  data: IUserConfigurationRequest
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.UserConfiguration.AddUserConfigurationActive(UserID),
      data,
    },
    onAction: {
      onStart: USER_CONFIGURATION_REQUESTED.type,
      onSuccess: ADD_USER_CONFIGURATION_SUCCESS.type,
      onError: USER_CONFIGURATION_FAILED.type,
    },
  });
};

export const GetUserConfiguration = (UserID: string) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.UserConfiguration.UserConfiguration(UserID),
    },
    onAction: {
      onStart: USER_CONFIGURATION_REQUESTED.type,
      onSuccess: USER_CONFIGURATION_SUCCESS.type,
      onError: USER_CONFIGURATION_FAILED.type,
    },
  });
};

export const AddUserConfiguration = (
  userId: string,
  data: { userConfigurationTypeID: number; value: string }
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.UserConfiguration.UserConfiguration(userId),
      data,
    },
    onAction: {
      onStart: USER_CONFIGURATION_ADD_REQUESTED.type,
      onSuccess: ADD_USER_CONFIGURATION_SUCCESS.type,
      onError: USER_CONFIGURATION_FAILED.type,
      showToast: true,
    },
  });
};
