import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
  Typography,
  Box,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useState } from 'react';

const HeaderSelectSearchable = ({
  label,
  defaultValue,
  onChange,
  menuItems,
  error,
}) => {
  const [inputValue, setInputValue] = useState('');
  const inputFiltered = menuItems.filter(
    (company) => company.value == defaultValue
  );
  return (
    <FormControl
      variant="standard"
      sx={{ width: { xs: '100%', sm: 150, md: 200 } }}
    >
      <Box>
        {error && (
          <Typography variant="caption" color="error">
            Campo obrigatório
          </Typography>
        )}
      </Box>
      <Autocomplete
        defaultValue={inputFiltered[0]}
        onChange={(event, newValue) => {
          newValue === null ? '' : onChange(newValue.value);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={menuItems}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            label={label}
            {...params}
            error={error}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            variant="standard"
          />
        )}
        isOptionEqualToValue={(option, value) => option.value === value.value}
      />
    </FormControl>
  );
};

export default HeaderSelectSearchable;
