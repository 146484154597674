import ImageIcon from '@mui/icons-material/Image';
import MapIcon from '@mui/icons-material/Map';
import {
  Box,
  Checkbox,
  IconButton,
  CircularProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import DataDisplay from '../../components/DataDisplay';
import CustomTable from '../../components/Table/CustomTable';
import InfoIcon from '@mui/icons-material/Info';
import { numberToCurrency } from '../../utils/format';
import { useDispatch, useSelector } from 'react-redux';
import { ModalWrapper } from '../../components/Modal';
import GoogleMapComponent from '../../components/GoogleMap';
import { GetExpenseEstimative } from '../../store/_Entities/ExpenseEstimative';
import ImageModal from '../../components/ModalImages';

const KmTable = ({ data, dataDisplayData, selectedRows, setSelectedRows }) => {
  const { approval, expenseEstimative } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();
  const [selectAll, setSelectAll] = useState(false);
  const [mapModal, setMapModal] = useState({
    show: false,
    origin: { lat: 0, lng: 0 },
  });
  const [showImageModal, setShowImageModal] = useState({
    show: false,
    image: [],
  });

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    setSelectedRows(
      event.target.checked ? data.map((row) => row.expenseID) : []
    );
  };

  const handleRowCheckboxChange = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleMapClick = (row) => {
    setMapModal({
      show: true,
      origin: {
        lat: row.origin.lat,
        lng: row.origin.lng,
      },
    });
    dispatch(GetExpenseEstimative(row.expenseID));
  };

  const handleExpenseFile = (row) => {
    const images = row.file.split(',');
    setShowImageModal({ show: true, image: images });
  };

  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Checkbox
          checked={selectAll}
          onChange={handleSelectAllChange}
          inputProps={{ 'aria-label': 'select all expenses' }}
        />
      ),
      renderCell: (params) => (
        <Checkbox
          checked={selectedRows.includes(params.row.expenseID)}
          onChange={() => handleRowCheckboxChange(params.row.expenseID)}
        />
      ),
    },
    {
      field: 'warningString',
      headerName: 'Alerta',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const warnings = params.row?.warningString;

        if (warnings) {
          const parser = new DOMParser();
          const warningText =
            parser.parseFromString(warnings, 'text/html').body.textContent ||
            '';
          return (
            <Tooltip
              title={<h1 style={{ fontSize: '15px' }}>{warningText}</h1>}
              placement="top"
            >
              <InfoIcon />
            </Tooltip>
          );
        }
        return null;
      },
    },
    {
      field: 'file',
      headerName: 'Arquivo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        return params.row.file !== null ? (
          <IconButton onClick={() => handleExpenseFile(params.row)}>
            <ImageIcon />
          </IconButton>
        ) : null;
      },
    },
    {
      field: 'price',
      headerName: 'Valor',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => {
        return numberToCurrency(params.value);
      },
    },
    {
      field: 'priceKm',
      headerName: 'Valor do KM',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => {
        return parseFloat(params.value).toFixed(2);
      },
    },
    {
      field: 'originAddress',
      headerName: 'Origem',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => params.row?.origin?.address || '',
    },
    {
      field: 'destinyAddress',
      headerName: 'Destino',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => params.row?.destination?.address || '',
    },
    {
      field: 'distanceString',
      headerName: 'Distância',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      valueFormatter: (params) => {
        return parseFloat(params.value).toFixed(2) + ' KM';
      },
    },
    {
      field: 'dateStr',
      headerName: 'Data',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'paymentType',
      headerName: 'Meio de Pagamento',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'project',
      headerName: 'Projeto',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'reason',
      headerName: 'Motivo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'justification',
      headerName: 'Justificativa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'map',
      headerName: 'Mapa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleMapClick(params.row)}>
          <MapIcon />
        </IconButton>
      ),
    },
    {
      field: 'expenseID',
      headerName: 'ID da Despesa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const handleCopy = () => {
          navigator.clipboard.writeText(params.value);
        };

        return (
          <Tooltip title="Copiar ID" onClick={handleCopy} arrow>
            <Typography variant="body2" style={{ cursor: 'pointer' }}>
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <DataDisplay data={dataDisplayData} />
      <CustomTable
        columns={columns}
        data={data}
        getRowId={(row) => row.expenseID}
        loading={approval?.loading}
        exportHeaders={{
          status: 'Status',
          alerta: 'Alerta',
          arquivo: 'Arquivo',
          valor: 'Valor',
          valorKM: 'Valor do KM',
          origem: 'Origem',
          destino: 'Destino',
          distancia: 'Distância',
          data: 'Data',
          descricao: 'Descrição',
          centroDeCusto: 'Centro de Custo',
          meioDePagamento: 'Meio de Pagamento',
          projeto: 'Projeto',
          motivo: 'Motivo',
          justificativaCliente: 'Justificativa Cliente',
          mapa: 'Mapa',
          expenseID: 'ID da Despesa',
        }}
      />
      <ImageModal
        closeModalAction={() => setShowImageModal({ show: false, image: [] })}
        images={showImageModal.image}
        showModal={showImageModal.show}
      />
      <ModalWrapper
        showModal={mapModal.show}
        title={'Mapa'}
        maxWidth={'lg'}
        closeModalAction={() =>
          setMapModal({ show: false, origin: { lat: 0, lng: 0 } })
        }
      >
        <Box width={'lg'}>
          {expenseEstimative?.loading ? (
            <CircularProgress />
          ) : (
            <GoogleMapComponent
              origin={mapModal.origin}
              estimative={expenseEstimative.estimative}
            />
          )}
        </Box>
      </ModalWrapper>
    </>
  );
};

export default KmTable;
