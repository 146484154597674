import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Container,
  Grid,
  Typography,
  Box,
  Select,
  MenuItem,
  TextField,
  Button,
  Checkbox,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import ResponsiveAppBar from '../../NavBar';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetCostCenters } from '../../../store/_Entities/CostCenter';
import ModalConfirm from '../../ModalConfirm';
import ModalErrorSuccess from '../../ModalErrorSuccess';
import { CreateUser } from '../../../store/_Entities/InviteUser';
import { GetCompanySources } from '../../../store/_Entities/CompanySource';

const costCenters = [
  { name: 'Todos', value: 'all' },
  { name: 'Menor Caminho', value: 'shortestPath' },
  { name: 'Sim', value: 'yes' },
  { name: 'Não', value: 'no' },
];

const InviteUsersForm = () => {
  const { companyID } = useParams();
  const { user, costCenter, inviteUser, companySource } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState({ show: false, type: null });
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    dispatch(GetCostCenters(companyID));
    dispatch(GetCompanySources(companyID));
  }, []);

  useEffect(() => {
    if (inviteUser.success && submitted) {
      setShowModal({ show: true, type: 'success' });
    }
  }, [inviteUser.success]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyID: +companyID,
      costCenterID: '',
      name: '',
      registration: '',
      email: '',
      perfil: '',
      cartao: false,
      frota: false,
      driver: false,
      expen: false,
      km: false,
    },
  });

  const onSubmit = (data) => {
    setSubmitted(true);
    dispatch(CreateUser(user.authData.sub, data));
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container sx={{ mt: 6 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            sx={{
              boxShadow: '0px 0px 4px 0px #00000040',
              borderRadius: '10px',
              padding: 2,
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h6">Usuários Convidados</Typography>
            </Grid>

            {/* Centro de Custo */}
            <Grid item xs={12}>
              <Controller
                name="costCenterID"
                control={control}
                rules={{ required: 'Campo obrigatório' }}
                render={({ field }) => (
                  <Box>
                    <label htmlFor="costCenterID">Centro de Custo</label>
                    <Autocomplete
                      id="costCenterID"
                      fullWidth
                      options={costCenter.costCenters}
                      getOptionLabel={(option) =>
                        `${option.costCenterID} - ${option.number} - ${option.description}`
                      }
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <Box key={key} {...optionProps}>
                           {option.number} - {option.description}
                          </Box>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Selecione..."
                          error={!!errors?.costCenterID}
                          helperText={errors?.costCenterID?.message}
                        />
                      )}
                      onChange={(_, newValue) =>
                        field.onChange(newValue?.costCenterID || '')
                      }
                      value={
                        costCenter.costCenters.find(
                          (c) => c.costCenterID === field.value
                        ) || null
                      }
                      filterOptions={(options, params) => {
                        const { inputValue } = params;

                        const filtered = options.filter((option) => 
                          String(`${option.number} - ${option.description}`).toUpperCase().includes(inputValue.toUpperCase())
                        );

                        return filtered;
                      }}
                    />
                  </Box>
                )}
              />
            </Grid>

            {/* Nome */}
            <Grid item xs={12} md={6}>
              <Controller
                name="name"
                control={control}
                rules={{ required: 'Campo obrigatório' }}
                render={({ field }) => (
                  <Box>
                    <label htmlFor="name">Nome</label>
                    <TextField
                      id="name"
                      variant="outlined"
                      {...field}
                      placeholder="Digite..."
                      fullWidth
                      error={Boolean(errors?.name)}
                    />
                    {errors?.name && (
                      <Typography variant="caption" color="error">
                        {errors?.name.message}
                      </Typography>
                    )}
                  </Box>
                )}
              />
            </Grid>

            {/* Matrícula */}
            <Grid item xs={12} md={6}>
              <Controller
                name="registration"
                control={control}
                rules={{ required: 'Campo obrigatório' }}
                render={({ field }) => (
                  <Box>
                    <label htmlFor="registration">Matrícula</label>
                    <TextField
                      id="registration"
                      variant="outlined"
                      {...field}
                      placeholder="Digite..."
                      fullWidth
                      error={Boolean(errors?.registration)}
                    />
                    {errors?.registration && (
                      <Typography variant="caption" color="error">
                        {errors?.registration.message}
                      </Typography>
                    )}
                  </Box>
                )}
              />
            </Grid>

            {/* E-mail */}
            <Grid item xs={12} md={6}>
              <Controller
                name="email"
                control={control}
                rules={{ required: 'Campo obrigatório' }}
                render={({ field }) => (
                  <Box>
                    <label htmlFor="email">E-mail</label>
                    <TextField
                      id="email"
                      variant="outlined"
                      {...field}
                      placeholder="Digite..."
                      fullWidth
                      error={Boolean(errors?.email)}
                    />
                    {errors?.email && (
                      <Typography variant="caption" color="error">
                        {errors?.email.message}
                      </Typography>
                    )}
                  </Box>
                )}
              />
            </Grid>

            {/* Perfil de Percurso */}
            {companySource?.companySources?.some((c) => c.source === 'Km') && (
              <Grid item xs={12} md={6}>
                <Controller
                  name="perfil"
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  render={({ field }) => (
                    <Box>
                      <label htmlFor="perfil">Perfil de Percurso</label>
                      <Select
                        id="perfil"
                        fullWidth
                        {...field}
                        displayEmpty
                        renderValue={
                          field.value === '' ? () => 'Selecione...' : undefined
                        }
                      >
                        {costCenters.map((center) => (
                          <MenuItem key={center.value} value={center.value}>
                            {center.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.perfil && (
                        <Typography variant="caption" color="error">
                          {errors?.perfil.message}
                        </Typography>
                      )}
                    </Box>
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} />

            {/* Cartão Combustível */}
            {companySource?.companySources?.some(
              (c) => c.source === 'Expen'
            ) && (
              <Grid item xs={12} md={4}>
                <Controller
                  name="cartao"
                  control={control}
                  render={({ field }) => (
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'start'}
                    >
                      <label htmlFor="cartao">Cartão Combustível</label>
                      <Box display={'flex'} alignItems={'center'}>
                        <Checkbox
                          id="cartao"
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                        {field.value ? 'Ativo' : 'Inativo'}
                      </Box>
                    </Box>
                  )}
                />
              </Grid>
            )}

            {/* Frota Própria */}
            {companySource?.companySources?.some((c) => c.source === 'Km') && (
              <Grid item xs={12} md={4}>
                <Controller
                  name="frota"
                  control={control}
                  render={({ field }) => (
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'start'}
                    >
                      <label htmlFor="frota">Frota Própria</label>
                      <Box display={'flex'} alignItems={'center'}>
                        <Checkbox
                          id="frota"
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                        {field.value ? 'Ativo' : 'Inativo'}
                      </Box>
                    </Box>
                  )}
                />
              </Grid>
            )}

            {/* Driver */}
            {companySource?.companySources?.some(
              (c) => c.source === 'Driver'
            ) && (
              <Grid item xs={12} md={4}>
                <Controller
                  name="driver"
                  control={control}
                  render={({ field }) => (
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'start'}
                    >
                      <label htmlFor="driver">Driver</label>
                      <Box display={'flex'} alignItems={'center'}>
                        <Checkbox
                          id="driver"
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                        {field.value ? 'Ativo' : 'Inativo'}
                      </Box>
                    </Box>
                  )}
                />
              </Grid>
            )}

            {/* Expen */}
            {companySource?.companySources?.some(
              (c) => c.source === 'Expen'
            ) && (
              <Grid item xs={12} md={4}>
                <Controller
                  name="expen"
                  control={control}
                  render={({ field }) => (
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'start'}
                    >
                      <label htmlFor="expen">Expen</label>
                      <Box display={'flex'} alignItems={'center'}>
                        <Checkbox
                          id="expen"
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                        {field.value ? 'Ativo' : 'Inativo'}
                      </Box>
                    </Box>
                  )}
                />
              </Grid>
            )}

            {/* Km */}
            {companySource?.companySources?.some((c) => c.source === 'Km') && (
              <Grid item xs={12} md={4}>
                <Controller
                  name="km"
                  control={control}
                  render={({ field }) => (
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'start'}
                    >
                      <label htmlFor="km">Km</label>
                      <Box display={'flex'} alignItems={'center'}>
                        <Checkbox
                          id="km"
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                        {field.value ? 'Ativo' : 'Inativo'}
                      </Box>
                    </Box>
                  )}
                />
              </Grid>
            )}
          </Grid>
          <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
            <Button variant="contained" type="submit">
              {inviteUser.loading ? (
                <CircularProgress color={'inherit'} size={25} thickness={5} />
              ) : (
                'Salvar'
              )}
            </Button>
            <Button
              variant="outlined"
              onClick={() => setShowModal({ show: true, type: 'cancel' })}
            >
              Cancelar
            </Button>
          </Box>
        </form>
        <ModalConfirm
          show={showModal.show && showModal.type === 'cancel'}
          handleClose={() => setShowModal({ show: false, type: null })}
          title={'Deseja Cancelar?'}
          submit={() => navigate('/adm/usuarios-convidados')}
        >
          <Box>
            <Typography variant="subtitle1">
              O convite para o usuário será cancelado.
            </Typography>
          </Box>
        </ModalConfirm>
        <ModalErrorSuccess
          show={showModal.show && showModal.type === 'success'}
          handleClose={() => navigate('/adm/usuarios-convidados')}
          title={'Cadastro!'}
        >
          <Box>
            <Typography variant="subtitle1">
              Usuário cadastrado com sucesso.
            </Typography>
          </Box>
        </ModalErrorSuccess>
      </Container>
    </>
  );
};

export default InviteUsersForm;
