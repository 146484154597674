import { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Box,
  IconButton,
  Button,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Header from '../../../components/Header';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import HeaderSearchSelect from '../../../components/HeaderSearchSelect';
import { DeleteUser, GetActiveUsers } from '../../../store/_Entities/User';
import { useNavigate } from 'react-router-dom';
import HeaderSearchSelectCompany from '../../../components/HeaderSearchSelectCompany';
import { setLastCompanyFiltered } from '../../../store/_Entities/Company';
import { ModalWrapper } from '../../../components/Modal';

const ActiveUsers = () => {
  const { user, company } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(() => {
    return company.lastCompanyFiltered || '';
  });
  const [selectedUser, setSelectedUser] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteUser = () => {
    const data = {
      origin: 'Portal 2.0',
      originID: user.authData.sub,
      status: 0,
      companyID: selectedUser.companyID,
    };
    dispatch(DeleteUser(selectedUser.userID, data));
    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (selectedCompany) {
      dispatch(GetActiveUsers(selectedCompany));
      dispatch(setLastCompanyFiltered(selectedCompany));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      setData(user.users);
    }
  }, [user.users]);

  const columns = [
    {
      field: 'companyID',
      headerName: 'Company ID',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'registration',
      headerName: 'Matrícula',
      headerAlign: 'center',
      align: 'center',
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Nome',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'Editar',
      headerName: 'Editar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={() =>
            navigate(
              `/adm/usuarios-ativos/edit/${selectedCompany}/${params.row.userID}`
            )
          }
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'Deletar',
      headerName: 'Deletar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setSelectedUser(params.row);
            setShowDeleteModal(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const exportHeaders = {
    companyID: 'Empresa ID',
    registration: 'Matrícula',
    name: 'Nome',
    costCenter: 'Centro de Custo',
    email: 'E-mail',
    phone: 'Telefone',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Header title="Usuários Ativos">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <HeaderSearchSelectCompany
                label={'Empresas'}
                onChange={setSelectedCompany}
                defaultValue={selectedCompany}
              />
            </Grid>
          </Grid>
        </Header>
        <Box sx={{ width: '100%', height: '100%' }}>
          <CustomTable
            columns={columns}
            data={data}
            getRowId={(row) => row.userID}
            exportHeaders={exportHeaders}
            loading={user.loading}
          />
        </Box>
      </Container>
      <ModalWrapper
        showModal={showDeleteModal}
        closeModalAction={() => setShowDeleteModal(false)}
        title="Deletar Usuário"
      >
        <Typography variant="body1">
          Tem certeza que deseja deletar este usuário?
        </Typography>
        <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
          <Button variant="contained" onClick={handleDeleteUser}>
            Confirmar
          </Button>
          <Button variant="outlined" onClick={() => setShowConfirmModal(true)}>
            Cancelar
          </Button>
        </Box>
      </ModalWrapper>
    </>
  );
};

export default ActiveUsers;
