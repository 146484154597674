// Centro de Custo
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonIcon from '@mui/icons-material/Person';
import { Container, IconButton, Box, Button, MenuItem } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Header from '../../../components/Header';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { ModalWrapper } from '../../../components/Modal';
import alerta from '../../../assets/images/alerta.png';
import { setLastCompanyFiltered } from '../../../store/_Entities/Company';
import HeaderSearchSelectCompany from '../../../components/HeaderSearchSelectCompany';
import {
  CreateCostCenter,
  DeleteCostCenter,
  EditCostCenter,
  GetCostCenters,
} from '../../../store/_Entities/CostCenter';
import FormSelect from '../../../components/FormSelect';
import FormInput from '../../../components/FormInput';
import { useForm, Controller } from 'react-hook-form';

const CostCenter = () => {
  const { company, costCenter } = useSelector((state) => state.entities);

  const [costCenters, setCostCenters] = useState([]);

  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [selectedCompany, setSelectedCompany] = useState(() => {
    return company.lastCompanyFiltered || '';
  });

  const [showAddModal, setShowAddModal] = useState({
    show: false,
    type: 'add',
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCostCenter, setSelectedCostCenter] = useState('');
  const [showError, setShowError] = useState(false);

  const onSubmit = (req) => {
    const data = {
      description: req.description,
      companyID: selectedCompany,
      productive: req.productive,
      number: req.number,
    };
    if (showAddModal.type === 'add') {
      dispatch(CreateCostCenter(data));
    }
    if (showAddModal.type === 'edit') {
      dispatch(EditCostCenter(selectedCostCenter.costCenterID, data));
    }
    setShowAddModal(false);
    reset();
  };

  const handleDeleteOnClick = (costCenterToDelete) => {
    setSelectedCostCenter(costCenterToDelete);
    setShowDeleteModal(true);
  };

  const handleEditOnClick = (costCenterEdit) => () => {
    setSelectedCostCenter(costCenterEdit);
    setShowAddModal({ show: true, type: 'edit' });
    setValue('description', costCenterEdit.description);
    setValue('number', costCenterEdit.number);
    setValue('productive', costCenterEdit.productive);
  };

  const handleConfirmedDelete = () => {
    dispatch(DeleteCostCenter(selectedCostCenter.costCenterID));
    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (selectedCompany != 'all') setCostCenters(costCenter.costCenters);
  }, [costCenter]);

  useEffect(() => {
    if (selectedCompany != 'all') {
      dispatch(GetCostCenters(selectedCompany));
      dispatch(setLastCompanyFiltered(selectedCompany));
      setShowError(false);
    }
  }, [selectedCompany]);

  const columns = [
    {
      field: 'number',
      headerName: 'Número do Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'productive',
      headerName: 'Produtivo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 40,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {params.row.productive === 1 ? 'Sim' : 'Não'}
        </Box>
      ),
    },
    {
      field: 'admins',
      headerName: 'Administradores',
      headerAlign: 'center',
      align: 'center',
      minWidth: 75,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          href={`centro-de-custo/administradores/${params.row.costCenterID}`}
        >
          <PersonIcon />
        </IconButton>
      ),
    },
    {
      field: 'supervisors',
      headerName: 'Grupo de Supervisores',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          href={`centro-de-custo/grupo-de-supervisores/${params.row.costCenterID}`}
        >
          <VisibilityIcon />
        </IconButton>
      ),
    },
    {
      field: 'Editar',
      headerName: 'Editar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 30,
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={handleEditOnClick(params.row)}>
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'Deletar',
      headerName: 'Deletar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 30,
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleDeleteOnClick(params.row)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const exportHeaders = {
    number: 'Número do Centro de Custo',
    description: 'Descrição',
    productive: 'Produtivo',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Header
          title="Centro de Custo"
          onClick={() =>
            selectedCompany
              ? setShowAddModal({
                  show: true,
                  type: 'add',
                })
              : setShowError(true)
          }
        >
          <HeaderSearchSelectCompany
            label={'Empresas'}
            onChange={setSelectedCompany}
            error={showError}
            defaultValue={selectedCompany}
          />
        </Header>
        <CustomTable
          columns={columns}
          data={costCenters}
          getRowId={(row) => row.costCenterID}
          exportHeaders={exportHeaders}
          loading={costCenter.loading}
        />

        <ModalWrapper
          showModal={showDeleteModal}
          closeModalAction={() => setShowDeleteModal(false)}
          title="Deseja Deletar o Centro de Custo?"
          img={alerta}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Box>{selectedCostCenter.description}</Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="contained" onClick={handleConfirmedDelete}>
              Confirmar
            </Button>
            <Button
              variant="outlined"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancelar
            </Button>
          </Box>
        </ModalWrapper>

        <ModalWrapper
          showModal={showAddModal.show}
          closeModalAction={() => {
            setShowAddModal((prev) => ({ ...prev, show: false }));
            reset();
          }}
          title={
            showAddModal.type === 'add'
              ? 'Cadastro de Centro de Custo'
              : 'Edição de Centro de Custo'
          }
          width={400}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Controller
                name="number"
                control={control}
                rules={{ required: 'Campo obrigatório' }}
                render={({ field }) => (
                  <FormInput
                    field={field}
                    label={'Número de controle'}
                    errors={errors.number}
                  />
                )}
              />
              <Controller
                name="description"
                control={control}
                rules={{ required: 'Campo obrigatório' }}
                render={({ field }) => (
                  <FormInput
                    field={field}
                    label={'Descrição'}
                    errors={errors.description}
                  />
                )}
              />
              <Controller
                name="productive"
                control={control}
                rules={{ required: 'Campo obrigatório' }}
                defaultValue={''}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    label={'Produtivo'}
                    errors={errors.productive}
                  >
                    <MenuItem value={1}>Sim</MenuItem>
                    <MenuItem value={0}>Não</MenuItem>
                  </FormSelect>
                )}
              />
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
              <Button variant="contained" type="submit">
                Salvar
              </Button>
              <Button
                variant="outlined"
                onClick={() =>
                  setShowAddModal((prev) => ({ ...prev, show: false }))
                }
              >
                Cancelar
              </Button>
            </Box>
          </form>
        </ModalWrapper>
      </Container>
    </>
  );
};

export default CostCenter;
