import { useForm, Controller, useWatch } from 'react-hook-form';
import {
  Container,
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
  Autocomplete
} from '@mui/material';
import FormInput from '../../../FormInput';
import FormSelect from '../../../FormSelect';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetUser } from '../../../../store/_Entities/User';
import { GetCostCenters } from '../../../../store/_Entities/CostCenter';
import { UpdateSelectedUser } from '../../../../store/_Entities/User';
import { useParams } from 'react-router-dom';

const PersonalData = ({ navigateBack, setShowSuccessModal }) => {
  const { companyID, userID } = useParams();
  const { user, costCenter } = useSelector((state) => state.entities);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const {
    setValue,
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyID: companyID,
      costCenterID: data?.costCenterID || '',
      name: data?.name || '',
      registration: data?.registration || '',
      email: data?.email || '',
      phone: data?.phone || '',
    },
  });

  const selectedCostCenter = useWatch({
    control,
    name: 'costCenterID',
  });

  const onSubmit = (reqData) => {
    const transformedData = {
      ...reqData,
      origin: 'Portal 2.0',
      originID: user.authData.sub,
    };
    dispatch(UpdateSelectedUser(data.userID, transformedData));
    setHasSubmitted(true);
  };

  useEffect(() => {
    if (userID) {
      dispatch(GetUser(userID, true));
    }
  }, []);

  useEffect(() => {
    if (user.selectedUser) {
      setData(user.selectedUser);
    }
  }, [user.selectedUser]);

  useEffect(() => {
    if (data) {
      const validCostCenter = costCenter.costCenters.find(
        (item) => item.costCenterID === data.costCenterID
      )
        ? data.costCenterID
        : '';

      reset({
        companyID: companyID,
        costCenterID: validCostCenter,
        name: data.name,
        registration: data.registration || '',
        email: data.email,
        phone: data.phone,
      });
    }
  }, [data, costCenter.costCenters]);

  useEffect(() => {
    if (hasSubmitted && user.success) {
      setShowSuccessModal({ show: true, type: 'Dados Pessoais' });
    }
  }, [hasSubmitted, user.success]);

  useEffect(() => {
    dispatch(GetCostCenters(companyID));
  }, []);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          sx={{
            boxShadow: '0px 0px 4px 0px #00000040',
            borderRadius: '10px',
            padding: 2,
          }}
        >
          {user.loading ? (
            <Box width={'100%'} textAlign={'center'}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid item xs={12} md={5}>
                <Controller
                  name="costCenterID"
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  render={({ field }) => (
                    <Box>
                      <label htmlFor="costCenterID">Centro de Custo</label>
                      <Autocomplete
                        id="costCenterID"
                        fullWidth
                        options={costCenter.costCenters}
                        getOptionLabel={(option) =>
                          `${option.costCenterID} - ${option.description}`
                        }
                        renderOption={(props, option) => {
                          const { key, ...optionProps } = props;
                          return (
                            <Box key={key} {...optionProps}>
                              {option.description}
                            </Box>
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Selecione..."
                            error={!!errors?.costCenterID}
                            helperText={errors?.costCenterID?.message}
                          />
                        )}
                        onChange={(_, newValue) =>
                          field.onChange(newValue?.costCenterID || '')
                        }
                        value={
                          costCenter.costCenters.find(
                            (c) => c.costCenterID === field.value
                          ) || null
                        }
                        filterOptions={(options, params) => {
                          const { inputValue } = params;

                          const filtered = options.filter((option) =>
                            String(`${option.description}`).toUpperCase().includes(inputValue.toUpperCase())
                          );

                          return filtered;
                        }}
                      />
                    </Box>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'start'}
                >
                  <InputLabel htmlFor="Supervisores">Supervisores</InputLabel>
                  <Select
                    id="Supervisores"
                    size="small"
                    displayEmpty
                    value={''}
                    fullWidth
                    renderValue={() => 'Selecione...'}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                  ></Select>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  render={({ field }) => (
                    <FormInput
                      field={field}
                      label={'Nome'}
                      errors={errors.name}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Controller
                  name="registration"
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  render={({ field }) => (
                    <FormInput
                      field={field}
                      label={'Registro'}
                      errors={errors.registration}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  render={({ field }) => (
                    <FormInput
                      field={field}
                      label={'E-mail'}
                      errors={errors.email}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Controller
                  name="phone"
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  render={({ field }) => (
                    <FormInput
                      field={field}
                      label={'Telefone'}
                      errors={errors.phone}
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
          <Button variant="contained" type="submit">
            Salvar
          </Button>
          <Button variant="outlined" onClick={navigateBack}>
            Voltar
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default PersonalData;
