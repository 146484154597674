import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import Header from '../../../components/Header';
import HeaderSelect from '../../../components/HeaderSelect';
import {
  Container,
  IconButton,
  Grid,
  Box,
  CircularProgress,
  Typography,
  TextField,
  Button,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info'; // Ícone para Despesas
import ApprovalIcon from '@mui/icons-material/Done'; // Ícone para Acompanhar Aprovação
import { useDispatch, useSelector } from 'react-redux';
import DatePickerRange from '../../../components/DatePickerRange';
import { useEffect, useState } from 'react';
import validateDate from '../../../utils/validateDate';
import {
  AdvancedApprove,
  GetApprovalFilter,
  GetApprovalFlow,
  GetEvaluate,
  RefuseApprove,
} from '../../../store/_Entities/Approval';
import { ModalWrapper } from '../../../components/Modal';
import ApprovalStepper from '../../../components/ApprovalStepper';
import { useNavigate } from 'react-router-dom';
import { inputformatNumber, numberToCurrency } from '../../../utils/format';
import CustomDataGrid from '../../../components/grids/components/CustomDataGrid';
import ImageIcon from '@mui/icons-material/Image';
import ImageModal from '../../../components/ModalImages';

const Approval = () => {
  const { approval, user } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reportStatus, setReportStatus] = useState(0);
  const [reportNature, setReportNature] = useState(0);
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); //Data 7 dias atrás
  const [endDate, setEndDate] = useState(new Date());

  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState({
    value: '',
    show: false,
    type: '',
  });
  const [justification, setJustification] = useState({
    error: false,
    value: '',
  });
  const [advancedPrice, setAdvancedPrice] = useState('');
  const [showImageModal, setShowImageModal] = useState({
    show: false,
    image: '',
  });

  const [data, setData] = useState([]);

  const handleReprove = () => {
    if (!justification.value) {
      setJustification({ error: true, value: justification.value });
      return;
    } else {
      dispatch(
        RefuseApprove(
          justification.value,
          selectedReport.value.reportID,
          advancedPrice.toString()
        )
      );
      setSelectedReport({ value: '', show: false, type: '' });
    }
  };

  const handleApprove = () => {
    dispatch(
      AdvancedApprove(selectedReport.value.reportID, advancedPrice.toString())
    );
    setSelectedReport({ value: '', show: false, type: '' });
  };

  const handleAprovar = (row) => {
    dispatch(GetApprovalFlow(row.reportID));
    setShowApprovalModal(true);
  };

  const handleExpenseFile = (row) => {
    const images = row.file.split(',').map((image) => image.trim());
    console.log(images);
    setShowImageModal({ show: true, image: images });
  };

  useEffect(() => {
    if (validateDate(startDate) && validateDate(endDate)) {
      const startDateFormatted = startDate.toLocaleDateString('pt-BR');
      const endDateFormatted = endDate.toLocaleDateString('pt-BR');
      dispatch(
        GetApprovalFilter(
          startDateFormatted,
          endDateFormatted,
          reportStatus,
          reportNature
        )
      );
    }
  }, [startDate, endDate, reportStatus, reportNature]);

  useEffect(() => {
    if (approval?.reports) {
      setData(approval?.reports);
    }
  }, [approval?.reports]);

  useEffect(() => {
    if (
      selectedReport.show &&
      selectedReport.value?.releaseType !== 'Adiantamento' &&
      selectedReport.value?.reportStatus !== 'Aguardando aprovação'
    ) {
      const releaseType =
        selectedReport.value?.releaseType === 'Reembolso de Despesas' ? 1 : 2;
      dispatch(
        GetEvaluate(
          selectedReport.value.reportID,
          user.user.userID,
          releaseType
        )
      );
    }
  }, [selectedReport]);

  const columns = [
    {
      field: 'despesas',
      headerName: 'Despesas',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            if (params.row.releaseType !== 'Adiantamento') {
              if (params.row.reportStatus === 'Aguardando aprovação') {
                navigate(`/evaluate/${params.row.reportID}`);
              } else {
                setSelectedReport({
                  value: params.row,
                  show: true,
                  type: 'notAdvanced',
                });
              }
            } else {
              setSelectedReport({
                value: params.row,
                show: true,
                type: 'advanced',
              });
              setAdvancedPrice(params.row.totalAmount);
            }
          }}
        >
          <InfoIcon />
        </IconButton>
      ),
    },
    {
      field: 'aprovar',
      headerName: 'Acompanhar Aprovação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleAprovar(params.row)}>
          <ApprovalIcon />
        </IconButton>
      ),
    },
    {
      field: 'reportID',
      headerName: 'Código',
      headerAlign: 'center',
      align: 'center',
      minWidth: 80,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Nome',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'totalAmount',
      headerName: 'Valor do Relatório',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => numberToCurrency(params.value),
    },
    {
      field: 'reportStatus',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'releaseType',
      headerName: 'Tipo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'created',
      headerName: 'Data',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString('pt-BR');
      },
    },
    {
      field: 'sentForApprovalDate',
      headerName: 'Data Enviado para Aprovação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString('pt-BR');
      },
    },
    {
      field: 'dataUltimaAprovacao',
      headerName: 'Data da Última Aprovação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
  ];

  const expensesColumns = [
    {
      field: 'expenseType',
      headerName: 'Tipo Despesa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Valor',
      headerAlign: 'center',
      align: 'center',
      minWidth: 80,
      flex: 1,
      valueFormatter: (params) => {
        return numberToCurrency(params.value);
      },
    },
    {
      field: 'currency',
      headerName: 'Moeda',
      headerAlign: 'center',
      align: 'center',
      minWidth: 70,
      flex: 1,
    },
    {
      field: 'dateStr',
      headerName: 'Data',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'file',
      headerName: 'Arquivo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        return params.row.file !== null ? (
          <IconButton onClick={() => handleExpenseFile(params.row)}>
            <ImageIcon />
          </IconButton>
        ) : null;
      },
    },
    {
      field: 'warnings',
      headerName: 'Alerta',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        if (params.row.warnings.length > 0) {
          const warnings = params.row.warnings
            .map((warning) => warning.expenseWarningType)
            .join(' - ');
          return (
            <Tooltip title={warnings} placement="top">
              <InfoIcon />
            </Tooltip>
          );
        }
        return null;
      },
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'paymentType',
      headerName: 'Meio de Pagamento',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'project',
      headerName: 'Projeto',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.customFields[0]?.description || '';
      },
    },
    {
      field: 'reason',
      headerName: 'Motivo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.customFields[1]?.description || '';
      },
    },
    {
      field: 'justification',
      headerName: 'Justificativa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.customFields[2]?.description || '';
      },
    },
  ];

  const exportHeaders = {
    reportID: 'Código',
    description: 'Descrição',
    name: 'Nome',
    totalAmount: 'Valor do Relatório',
    reportStatus: 'Status',
    costCenter: 'Centro de Custo',
    releaseType: 'Tipo',
    created: 'Data',
    dataUltimaAprovacao: 'Data da Última Aprovação',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Header title="Aprovação">
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={12} sm={2.5}>
              <HeaderSelect
                label="Status Relatório"
                onChange={(event) => {
                  setReportStatus(event.target.value);
                }}
                menuItems={[
                  { label: 'Todos', value: 0 },
                  { label: 'Aguardando Aprovação', value: 2 },
                  { label: 'Aprovado', value: 3 },
                  { label: 'Recusado', value: 4 },
                  { label: 'Pagamento Confirmado', value: 5 },
                  { label: 'Pagamento Recusado', value: 6 },
                  { label: 'Adiatamento Finalizado', value: 7 },
                ]}
                defaultValue={undefined}
              />
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <HeaderSelect
                label="Natureza relatório"
                onChange={(event) => {
                  setReportNature(event.target.value);
                }}
                menuItems={[
                  { label: 'Todos', value: 0 },
                  { label: 'Reembolso de Depsesas', value: 1 },
                  { label: 'Prestação de Contas', value: 2 },
                  { label: 'Adiantamento', value: 3 },
                ]}
                defaultValue={undefined}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <DatePickerRange
                startDate={startDate}
                endDate={endDate}
                onChange={{ startDate: setStartDate, endDate: setEndDate }}
              />
            </Grid>
          </Grid>
        </Header>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.reportID}
          exportHeaders={exportHeaders}
          loading={
            approval?.loading && !showApprovalModal && !selectedReport.show
          }
        />
        <ModalWrapper
          title={'Visualizar Fluxo de Aprovação'}
          showModal={showApprovalModal}
          closeModalAction={() => setShowApprovalModal(false)}
          maxWidth={
            approval?.approvalFlow?.approversGroup?.length > 3 ? 'md' : 'sm'
          }
        >
          <Box>
            <Typography variant="h6">Status da aprovação:</Typography>
            <Box display={'flex'} justifyContent={'center'}>
              {approval?.loading ? (
                <Box display={'flex'} justifyContent={'center'}>
                  <CircularProgress />
                </Box>
              ) : (
                <ApprovalStepper
                  data={approval?.approvalFlow?.approversGroup}
                />
              )}
            </Box>
          </Box>
        </ModalWrapper>
        <ModalWrapper
          title={
            `Avaliar relatório: ${selectedReport.value?.description}` || ''
          }
          showModal={selectedReport.show}
          closeModalAction={() =>
            setSelectedReport({ value: '', show: false, type: '' })
          }
          width="50%"
        >
          <Box width={'100%'} justifyContent={'center'}>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              {selectedReport.value?.releaseType === 'Adiantamento' &&
                selectedReport.value?.reportStatusID === 2 && (
                  <>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      gap={2}
                    >
                      <Box>Moeda: {selectedReport.value?.currency}</Box>
                      <Box>
                        <TextField
                          label="Valor"
                          value={numberToCurrency(advancedPrice)}
                          onChange={(e) => {
                            const formattedValue = inputformatNumber(
                              e.target.value
                            );

                            setAdvancedPrice(formattedValue);
                          }}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <TextField
                        label="Justificativa"
                        fullWidth
                        value={justification.value}
                        error={justification.error}
                        onChange={(e) =>
                          setJustification({
                            error: false,
                            value: e.target.value,
                          })
                        }
                      />
                    </Box>
                    <Box>
                      <Typography variant="caption">
                        *Obrigatório para Reprovar ou Aprovar com mudança no
                        valor
                      </Typography>
                    </Box>
                    <Box
                      display={'flex'}
                      gap={2}
                      justifyContent={'space-around'}
                    >
                      <Button variant="outlined" onClick={handleReprove}>
                        Reprovar
                      </Button>
                      <Button variant="contained" onClick={handleApprove}>
                        Aprovar
                      </Button>
                    </Box>
                  </>
                )}

              {selectedReport.value?.releaseType === 'Adiantamento' &&
                selectedReport.value?.reportStatusID !== 2 && (
                  <Grid container>
                    <Grid item xs={12}>
                      <Box width={'100%'} display={'flex'} gap={2}>
                        <Typography variant="body1" fontWeight={'medium'}>
                          Descrição do Adiantamento:
                        </Typography>
                        <Typography variant="body1">
                          {selectedReport.value?.description}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box width={'100%'} display={'flex'} gap={2}>
                        <Typography variant="body1" fontWeight={'medium'}>
                          Moeda do Adiantamento:
                        </Typography>
                        <Typography variant="body1">
                          {selectedReport.value?.currency}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box width={'100%'} display={'flex'} gap={2}>
                        <Typography variant="body1" fontWeight={'medium'}>
                          Valor do Adiantamento:
                        </Typography>
                        <Typography variant="body1">
                          {selectedReport.value?.totalAmount}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}

              {selectedReport.value?.releaseType !== 'Adiantamento' && (
                <Box
                  width={{
                    xs: '100%',
                  }}
                >
                  {approval?.loading ? (
                    <Box display={'flex'} justifyContent={'center'}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <CustomDataGrid
                        rows={approval?.evaluateExpenses?.expenses || []}
                        props={{
                          getRowId: (row) => row.expenseID,
                          loading: approval?.loading,
                          disableSelectionOnClick: true,
                        }}
                        columns={expensesColumns}
                        noRowsText={'Nenhuma despesa encontrada'}
                        height={500}
                      />
                    </>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </ModalWrapper>

        <ImageModal
          closeModalAction={() => setShowImageModal({ show: false, image: '' })}
          showModal={showImageModal.show}
          loading={approval.loading}
          images={showImageModal.image}
        />
      </Container>
    </>
  );
};

export default Approval;
