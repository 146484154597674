import { Autocomplete, TextField, Box, Typography } from '@mui/material';

const HeaderSearchSelect = ({
  label,
  onChange,
  inputValue,
  inputChange,
  menuItems,
  error,
  disabled,
}) => {
  return (
    <Box sx={{ width: { xs: '100%', sm: 150, md: 200 } }}>
      {error && (
        <Typography variant="caption" color="error">
          Campo obrigatório
        </Typography>
      )}
      <Autocomplete
        onChange={(event, newValue) => {
          onChange(newValue?.value || '');
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          inputChange(newInputValue);
        }}
        id="controllable-states-demo"
        options={menuItems}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            label={label}
            {...params}
            error={error}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            variant="standard"
          />
        )}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disabled={disabled}
      />
    </Box>
  );
};

export default HeaderSearchSelect;
