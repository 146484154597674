import {
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  Button,
  TextField,
  Card,
  CardContent,
} from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import ExpenseTable from './ExpenseTable';
import ResponsiveAppBar from '../../components/NavBar';
import KmTable from './KmTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GetEvaluateReport } from '../../store/_Entities/Approval';
import { numberToCurrency } from '../../utils/format';
import AddIcon from '@mui/icons-material/Add';
import BlockIcon from '@mui/icons-material/Block';
import ImageIcon from '@mui/icons-material/Image';
import CheckIcon from '@mui/icons-material/Check';
import { ApprovalApprove } from '../../store/_Entities/Approval';
import {
  CheckAttachments,
  DownloadAttachments,
} from '../../store/_Entities/ExpensesReport';
import { EXPENSES_CLEAR_DOWNLOAD_FILE } from '../../store/_Entities/ExpensesReport';
import ModalErrorSuccess from '../../components/ModalErrorSuccess';
import { ModalWrapper } from '../../components/Modal';
import { ApprovalRefuse } from '../../store/_Entities/Approval';
import DataDisplay from '../../components/DataDisplay';
import ModalConfirm from '../../components/ModalConfirm';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Evaluate() {
  const { reportID } = useParams();
  const { user, approval, expensesReport } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState(false);
  const [showRefuseModal, setShowRefuseModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState({
    show: false,
    submit: false,
    error: false,
  });

  const expenseCardsData = useMemo(() => {
    const despesas = [
      {
        label: 'Valor Despesas Totais',
        valor:
          (approval.evaluate?.expenseCurrency?.[0]?.currency ?? '') +
          ' ' +
          numberToCurrency(
            approval.evaluate?.expenseCurrency?.[0]?.price ?? ''
          ),
      },
      {
        label: 'Valor Despesas Cadastradas',
        valor:
          (approval.evaluate?.registeredCurrency?.[0]?.currency ?? '') +
          ' ' +
          numberToCurrency(
            approval.evaluate?.registeredCurrency?.[0]?.price ?? ''
          ),
      },
    ];

    // Conditionally add ticketCurrency if it exists
    if (approval.evaluate?.ticketCurrency?.[0]?.currency) {
      despesas.push({
        label: 'Valor Despesas Integração',
        valor:
          (approval.evaluate?.ticketCurrency?.[0]?.currency ?? '') +
          ' ' +
          numberToCurrency(approval.evaluate?.ticketCurrency?.[0]?.price ?? ''),
      });
    }

    return { despesas };
  }, [approval.evaluate, numberToCurrency]);

  const kmCardsData = useMemo(
    () => ({
      despesas: [
        {
          label: 'Valor Despesas KM',
          valor:
            (approval.evaluate?.totalCurrency?.[0]?.currency ?? '') +
            ' ' +
            numberToCurrency(approval.evaluate?.kmCurrency?.[0]?.price ?? ''),
        },
        {
          label: 'Distância Percorrida',
          valor:
            numberToCurrency(
              approval.evaluate?.kmCurrency?.[0]?.distance ?? ''
            ) + ' KM',
        },
        ...approval.evaluate?.kmTypes?.map((item) => ({
          label:
            item.providerID === 2
              ? 'KM GPS'
              : item.providerID === 3
              ? 'KM Manual'
              : 'KM Interno',
          valor: numberToCurrency(item.count ?? '') + '%',
        })),
      ],
    }),
    [approval.evaluate, numberToCurrency]
  );

  const ticketCardsData = useMemo(
    () => ({
      despesas: [
        {
          label: 'Valor Despesas Integração',
          valor:
            (approval.evaluate?.ticketCurrency?.[0]?.currency ?? '') +
            ' ' +
            numberToCurrency(
              approval.evaluate?.ticketCurrency?.[0]?.price ?? ''
            ),
        },
      ],
    }),
    [approval.evaluate, numberToCurrency]
  );

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleApprove = () => {
    dispatch(ApprovalApprove(user.user.userID, reportID));
    setSubmitted(true);
  };

  const handleReprove = () => {
    if (description === '') {
      setDescriptionError(true);
      return;
    }
    const expensesID =
      selectedRows.length > 0 ? selectedRows.join('&ExpensesID=') : null;
    console.log('expensesID', expensesID);
    console.log('Description', description);
    dispatch(
      ApprovalRefuse(user.user.userID, description, reportID, expensesID)
    );
    setShowRefuseModal(false);
    setSubmitted(true);
  };

  const handleDownloadAttachs = () => {
    dispatch(CheckAttachments(reportID));
    setShowDownloadModal((prev) => ({ ...prev, submit: true }));
  };

  useEffect(() => {
    dispatch(GetEvaluateReport(reportID));
  }, []);

  useEffect(() => {
    if (submitted && !approval.loading && approval.success && !approval.error) {
      setShowConfirmModal(true);
    }
  }, [submitted, approval]);

  useEffect(() => {
    if (
      expensesReport.checkAttachments &&
      showDownloadModal.submit &&
      expensesReport.success &&
      !expensesReport.loading
    ) {
      dispatch(DownloadAttachments(reportID));
      setShowDownloadModal({
        show: true,
        submit: false,
        error: false,
      });
    }
    if (
      showDownloadModal.submit &&
      !expensesReport.checkAttachments &&
      expensesReport.success &&
      !expensesReport.loading
    ) {
      setShowDownloadModal({
        show: true,
        submit: false,
        error: true,
      });
    }
    EXPENSES_CLEAR_DOWNLOAD_FILE();
  }, [expensesReport, reportID]);

  useEffect(() => {
    if (expensesReport.downloadFile && showDownloadModal.submit) {
      const file = expensesReport.downloadFile;
      const url = window.URL.createObjectURL(
        new Blob([file], { type: 'application/zip' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Anexos.zip');
      document.body.appendChild(link);
      link.click();
      dispatch(EXPENSES_CLEAR_DOWNLOAD_FILE());
    }
  }, [expensesReport.downloadFile, showDownloadModal.submit]);

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => navigate('/expen/aprovacao')}
                  sx={{
                    mt: 2,
                  }}
                >
                  Voltar
                </Button>
                <h2>
                  Avaliar relatório: {approval.evaluate?.reportDescription}
                </h2>
                <h2>Usuário: {approval.evaluate?.userName}</h2>

                <Typography variant="h6">
                  Valor Despesas Relatório:{' '}
                  {approval.evaluate?.totalCurrency?.[0]?.currency || ''}{' '}
                  {numberToCurrency(
                    approval.evaluate?.totalCurrency?.[0]?.price || ''
                  )}
                </Typography>
              </Box>
              <Box>
                <Box
                  display={'flex'}
                  justifyContent={'flex-end'}
                  gap={2}
                  sx={{ mt: { xs: 2, md: 0 } }}
                >
                  <Button
                    variant="contained"
                    onClick={() => setShowRefuseModal(true)}
                  >
                    <BlockIcon sx={{ mr: 1 }} />
                    Reprovar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setShowApproveModal(true)}
                  >
                    <CheckIcon sx={{ mr: 1 }} />
                    Aprovar
                  </Button>
                  <Button variant="contained" onClick={handleDownloadAttachs}>
                    <ImageIcon sx={{ mr: 1 }} />
                    Download Anexos
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  flexWrap: 'wrap',
                }}
              >
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  aria-label="Expense Tabs"
                >
                  <Tab label="Tabela de Despesas" />
                  {(approval?.evaluate?.kmsList || []).length > 0 && (
                    <Tab label="Tabela de KMs" />
                  )}
                </Tabs>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tabIndex} index={0}>
              <ExpenseTable
                data={approval.evaluate?.expensesList || []}
                dataDisplayData={expenseCardsData}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            </TabPanel>
            {(approval?.evaluate?.kmsList || []).length > 0 && (
              <TabPanel value={tabIndex} index={1}>
                <KmTable
                  data={approval.evaluate?.kmsList || []}
                  dataDisplayData={kmCardsData}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                />
              </TabPanel>
            )}
          </Grid>
        </Grid>
        <ModalWrapper
          showModal={showRefuseModal}
          closeModalAction={() => setShowRefuseModal(false)}
          title="Justificativa"
        >
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <TextField
              fullWidth
              value={description}
              placeholder="Digite..."
              onChange={(e) => setDescription(e.target.value)}
              error={descriptionError}
            />
            {descriptionError && (
              <Typography variant="caption" color="error">
                Justificativa é obrigatória
              </Typography>
            )}
            <Box display={'flex'} gap={2}>
              <Button
                variant="outlined"
                onClick={() => setShowRefuseModal(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleReprove}
              >
                Reprovar
              </Button>
            </Box>
          </Box>
        </ModalWrapper>

        <ModalErrorSuccess
          handleClose={() =>
            setShowDownloadModal({ show: false, submit: false, error: false })
          }
          show={showDownloadModal.show}
          title={'Alerta!'}
          error={true}
        >
          <Box>
            <Typography variant="subtitle1">
              {expensesReport.checkAttachments
                ? 'Ocorreu um erro!'
                : 'Esse relatório não contém anexos'}
            </Typography>
          </Box>
        </ModalErrorSuccess>

        <ModalErrorSuccess
          show={showConfirmModal}
          handleClose={() => navigate('/expen/aprovacao')}
          title={'Ação realizada com sucesso!'}
          loading={approval.loading}
          submit={() => navigate('/expen/aprovacao')}
        />
        <ModalConfirm
          show={showApproveModal}
          handleClose={() => setShowApproveModal(false)}
          submit={handleApprove}
          title="Aprovar o Relatório"
        >
          <Box>
            <Typography variant="subtitle1">
              Deseja Aprovar o Relatório?
            </Typography>
          </Box>
        </ModalConfirm>
      </Container>
    </>
  );
}

export default Evaluate;
