import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Button,
  Typography,
  IconButton,
  MenuItem,
} from '@mui/material';
import ResponsiveAppBar from '../../../NavBar';
import Footer from '../../../Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomDataGrid from '../../../grids/components/CustomDataGrid';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalConfirm from '../../../ModalConfirm';
import { ModalWrapper } from '../../../Modal';
import { useForm, Controller } from 'react-hook-form';
import FormSelect from '../../../FormSelect';
import {
  CreateSupervisor,
  DeleteSupervisor,
  GetSupervisor,
} from '../../../../store/_Entities/Supervisor';
import { GetActiveUsers } from '../../../../store/_Entities/User';

const Supervisor = () => {
  const { costCenterID, supervisionGroupID } = useParams();
  const { supervisionGroup, supervisor, company, user } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const selectedSupervisionGroup =
    supervisionGroup.supervisionGroups.find(
      (sg) => sg.supervisionGroupID == supervisionGroupID
    ) || {};

  useEffect(() => {
    dispatch(GetSupervisor(supervisionGroupID));
  }, []);

  useEffect(() => {
    if (showAddModal) {
      dispatch(GetActiveUsers(company.lastCompanyFiltered));
    }
  }, [showAddModal]);

  const onSubmit = (req) => {
    setSubmitted(true);
    const data = {
      supervisionGroupID: +supervisionGroupID,
      userID: req.userID,
    };
    dispatch(CreateSupervisor(data));
    setShowAddModal(false);
    reset();
  };

  const handleDeleteSupervisor = () => {
    dispatch(DeleteSupervisor(selectedItem.supervisorID));
    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (submitted && !supervisor.loading) {
      dispatch(GetSupervisor(supervisionGroupID));
      setSubmitted(false);
    }
  }, [supervisor]);

  const columns = [
    {
      field: 'supervisorID',
      headerName: 'Código',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'name',
      headerName: 'Nome',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'actions',
      headerName: 'Deletar',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setShowDeleteModal(true);
            setSelectedItem(params.row);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <ResponsiveAppBar />
      <Container>
        <Box xs={12} py={1} mb={'10px'}>
          <h2>Supervisores - {selectedSupervisionGroup.description}</h2>
        </Box>
        <Box>
          <Box>
            <Box
              mt={5}
              minHeight={200}
              boxShadow={'0px 0px 4px 0px #00000040'}
              p={5}
              borderRadius={2}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
            >
              <Box display={'flex'} justifyContent={'space-between'} mb={2}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    navigate(
                      `/adm/centro-de-custo/grupo-de-supervisores/${costCenterID}`
                    )
                  }
                >
                  Voltar
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setShowAddModal(true)}
                >
                  Adicionar
                </Button>
              </Box>

              <CustomDataGrid
                columns={columns}
                rows={supervisor.supervisors || []}
                noRowsText
                props={{
                  getRowId: (row) => row.supervisorID,
                  disableSelectionOnClick: true,
                  loading: supervisor.loading && !showDeleteModal,
                }}
              />
            </Box>
            <Box
              display={'flex'}
              justifyContent={'flex-end'}
              mt={2}
              gap={2}
            ></Box>
          </Box>
        </Box>
        <Footer />
        <ModalConfirm
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          title={'Deseja Deletar o Supervisor?'}
          submit={handleDeleteSupervisor}
          loading={supervisionGroup.loading}
        >
          <Box textAlign={'center'}>
            <Typography variant="subtitle">
              O Supervisor será deletado permanentemente.
            </Typography>
          </Box>
        </ModalConfirm>

        <ModalWrapper
          showModal={showAddModal}
          closeModalAction={() => {
            setShowAddModal(false);
          }}
          title={'Cadastro de  de Supervisor'}
          width={400}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            width={'150%'}
            p={2}
          >
            <Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="userID"
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  defaultValue={''}
                  render={({ field }) => (
                    <FormSelect
                      field={field}
                      label={'Usuários'}
                      errors={errors.userID}
                    >
                      {user.activeUsers.map((user) => (
                        <MenuItem key={user.userID} value={user.userID}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </FormSelect>
                  )}
                />
                <Box
                  display={'flex'}
                  justifyContent={'flex-end'}
                  mt={2}
                  gap={2}
                >
                  <Button variant="contained" type="submit">
                    Salvar
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => setShowAddModal(false)}
                  >
                    Cancelar
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </ModalWrapper>
      </Container>
    </>
  );
};

export default Supervisor;
