import { useState, useEffect } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { Box, Button, MenuItem, Autocomplete, TextField } from '@mui/material';
import { ModalWrapper } from '../../../../Modal';
import FormSelect from '../../../../FormSelect';
import { useDispatch, useSelector } from 'react-redux';
import { GetCostCenters } from '../../../../../store/_Entities/CostCenter';
import { GetUsersByCostCenter } from '../../../../../store/_Entities/User';

const UsersForm = ({
  policyGroupID,
  companyID,
  showModal,
  closeModalAction,
  onSubmit,
}) => {
  const { user, costCenter } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyID: companyID,
      policyGroupID: policyGroupID,
      userID: '',
      costCenterID: '',
    },
  });

  const selectedCostCenter = useWatch({
    control,
    name: 'costCenterID',
  });

  useEffect(() => {
    if (companyID && showModal) {
      dispatch(GetCostCenters(companyID));
    }
  }, [showModal]);

  useEffect(() => {
    if (selectedCostCenter) {
      dispatch(
        GetUsersByCostCenter(
          `?CompanyID=${companyID}&costCenterID=${selectedCostCenter}`
        )
      );
    }
  }, [selectedCostCenter]);

  return (
    <ModalWrapper
      showModal={showModal}
      closeModalAction={() =>
        closeModalAction((prev) => ({ ...prev, show: false }))
      }
      title="Cadastrar Usuário"
    >
      <Box width={400}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="costCenterID"
            control={control}
            rules={{ required: 'Campo obrigatório' }}
            render={({ field }) => (
              <Box>
                <label htmlFor="costCenterID">Centro de Custo</label>
                <Autocomplete
                  id="costCenterID"
                  fullWidth
                  options={costCenter.costCenters}
                  getOptionLabel={(option) =>
                    `${option.costCenterID} - ${option.description}`
                  }
                  renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    return (
                      <Box key={key} {...optionProps}>
                        {option.description}
                      </Box>
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Selecione..."
                      error={!!errors?.costCenterID}
                      helperText={errors?.costCenterID?.message}
                    />
                  )}
                  onChange={(_, newValue) =>
                    field.onChange(newValue?.costCenterID || '')
                  }
                  value={
                    costCenter.costCenters.find(
                      (c) => c.costCenterID === field.value
                    ) || null
                  }
                  filterOptions={(options, params) => {
                    const { inputValue } = params;

                    const filtered = options.filter((option) =>
                      String(`${option.description}`).toUpperCase().includes(inputValue.toUpperCase())
                    );

                    return filtered;
                  }}
                />
              </Box>
            )}
          />
          <Controller
            name="userID"
            control={control}
            rules={{ required: 'Campo obrigatório' }}
            render={({ field }) => (
              <FormSelect
                field={field}
                label={'Usuário'}
                errors={errors.costCenterID}
              >
                {selectedCostCenter && [
                  <MenuItem key="all" value="all">
                    TODOS
                  </MenuItem>,
                  ...user.users.map((user) => (
                    <MenuItem key={user.userID} value={user.userID}>
                      {user.name}
                    </MenuItem>
                  )),
                ]}
              </FormSelect>
            )}
          />
          <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
            <Button
              variant="outlined"
              onClick={() => closeModalAction({ show: false })}
            >
              Cancelar
            </Button>
            <Button variant="contained" type="submit">
              Salvar
            </Button>
          </Box>
        </form>
      </Box>
    </ModalWrapper>
  );
};

export default UsersForm;
